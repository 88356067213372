import axios from 'axios'
import { clearStorage } from './commonFunction'
export const HTTPService = async ({ url, method, data = {}, headers = {} }) => {
  const accessToken = localStorage.getItem('accessToken')
  headers = { ...headers }
  if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`

  const BASE_URL = process.env.REACT_APP_API_URL
  try {
    let response = await axios({
      url: BASE_URL + url,
      method,
      data: data,
      headers: {
        ...headers,
      },
    })
    if (
      response.data &&
      response.data.error &&
      response.data.error === 'InvalidToken'
    ) {
      clearStorage()
      window.location.replace('/login')
      return
    }
    return response.data
  } catch (ex) {
    throw ex
  }
}
