import React, { Component } from 'react'
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  DatePicker,
  message,
  TimePicker,
  Spin,
  Checkbox,
  Switch,
} from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import './ImportSampleOrder.scss'
import { HTTPService } from '../../otherServices/apiService'
import moment from 'moment'
import {
  DATE_FORMAT,
  TIME_FORMAT,
  PERMISSIONS_BY_NAME,
} from '../../otherServices/constant'
import { trimSpace, isPermission } from '../../otherServices/commonFunction'

const { Option } = Select

class ImportSampleOrder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: null,
      loader: false,
      file: null,
      isDistributionData: false,
      users: [],
    }
    this.getFormData = this.getFormData.bind(this)
    this.handleFinish = this.handleFinish.bind(this)
  }

  //function to fetch form data
  componentDidMount() {
    this.getFormData()
  }
  async getFormData() {
    this.setState({ loader: true })
    const data = { ftp_purpose: this.props.ftp_purpose }
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: `ftp-detail/?ftp_purpose=${this.props.ftp_purpose}`,
      })
      if (userRes.success) {
        let startDate = moment(userRes.data.start_date)
        let starTime = moment(userRes.data.start_time, TIME_FORMAT)
        userRes.data.start_date = startDate
        userRes.data.start_time = starTime
        this.setState({
          formData: userRes.data,
        })
        this.setState({ loader: false })
      } else {
        message.error(userRes.message)
        this.setState({ loader: false })
      }
    } catch (error) {
      this.setState({ loader: false })
      message.error('Failed to Fetch')
    }
  }

  //function to submit form
  async handleFinish(values) {
    let valueData = trimSpace(values)
    let { manual_upload_file, ...otherData } = valueData
    this.setState({ loader: true })
    valueData.ftp_purpose = this.props.ftp_purpose
    let startDate = valueData.start_date
    let startTime = valueData.start_time
    valueData.start_date = moment(startDate).format(DATE_FORMAT)
    valueData.start_time = moment(startTime).format(TIME_FORMAT)
    valueData.dragger = ''
    valueData.ftp_url = valueData.ftp_url || ''
    valueData.manual_upload_file = this.state.file
    let formData = new FormData()
    for (let key in valueData) {
      formData.set(key, valueData[key])
    }
    try {
      let userRes = await HTTPService({
        method: 'POST',
        url: 'ftp-detail/save/',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (userRes.success) {
        this.getFormData()
        message.success('Form Submited Successfully')
      } else {
        this.setState({ loader: false })
        message.error(userRes.message)
      }
    } catch (error) {
      this.setState({ loader: false })
      message.error('Failed to Update', error)
    }
  }

  renderFields() {
    const ftp_purpose = this.props.ftp_purpose
    let archiveFolder
    let folderName
    let lableName1
    let lableName2
    if (ftp_purpose === 'CatalogImport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Catalog Import'
      lableName2 = 'Catalog Archive'
    }
    if (ftp_purpose === 'PulseOrderImport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'PulseOrder Import'
      lableName2 = 'PulseOrder Archive'
    }
    if (ftp_purpose === 'PulseOrderError') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'PulseOrder Error'
      lableName2 = 'PulseOrder Error Archive'
    }
    if (ftp_purpose === 'PulseOrderSuccess') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'PulseOrder Success'
      lableName2 = 'PulseOrder Success Archive'
    }
    if (ftp_purpose === 'SampleOrderImproperFileFormatsImport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'SampleOrderImproperFileFormats Import'
      lableName2 = 'SampleOrderImproperFileFormats Archive'
    }
    if (ftp_purpose === 'SampleOrderImport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Sample Order Import'
      lableName2 = 'Sample Order Archive'
    }
    if (ftp_purpose === 'SampleOrderApprovalImport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Sample Order Approval Import'
      lableName2 = 'Sample Order Approval Archive'
    }
    if (ftp_purpose === 'SampleOrderExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Sample Order Export'
      lableName2 = 'Sample Order Export Archive'
    }
    if (ftp_purpose === 'CustomerExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Customer Export'
      lableName2 = 'Customer Export Archive'
    }
    if (ftp_purpose === 'ISLImport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'ISL Import'
      lableName2 = 'ISL Import Archive'
    }
    if (ftp_purpose === 'ISLExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'ISL Export'
      lableName2 = 'ISL Export Archive'
    }
    if (ftp_purpose === 'IDTImport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'IDT Import'
      lableName2 = 'IDT Import Archive'
    }
    if (ftp_purpose === 'IDTExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'IDT Export'
      lableName2 = 'IDT Export Archive'
    }
    if (ftp_purpose === 'DKImport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'DK Import'
      lableName2 = 'DK Import Archive'
    }
    if (ftp_purpose === 'DKExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'DK Export'
      lableName2 = 'DK Export Archive'
    }
    if (ftp_purpose === 'ShipmentAckDKImport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Shipment Ack DK Import'
      lableName2 = 'Shipment DK Archive'
    }
    if (ftp_purpose === 'ShipmentAckISLImport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Shipment Ack ISL Import'
      lableName2 = 'Shipment ISL Archive'
    }
    if (ftp_purpose === 'ShipmentAckIDTImport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Shipment Ack IDT Import'
      lableName2 = 'Shipment IDT Archive'
    }
    if (ftp_purpose === 'ErrorOrderDKImport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Error Order DK Import'
      lableName2 = 'Error Order DK Archive'
    }
    if (ftp_purpose === 'ErrorOrderIDTImport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'ErrorOrderIDTImport'
      lableName2 = 'Error Order IDT Archive'
    }
    if (ftp_purpose === 'ErrorOrderISLImport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Error Order ISL Import'
      lableName2 = 'Error Order ISL Archive'
    }
    if (ftp_purpose === 'DuplicateOrderIDTImport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Duplicate Order IDT Import'
      lableName2 = 'Duplicate Order IDT Archive'
    }
    if (ftp_purpose === 'DuplicateOrderDKImport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Duplicate Order DK Import'
      lableName2 = 'Duplicate Order DK Archive'
    }
    if (ftp_purpose === 'DuplicateOrderISLImport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Duplicate Order ISL Import'
      lableName2 = 'Duplicate Order ISL Archive'
    }
    if (ftp_purpose === 'BackorderDKImport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Backorder DK Import'
      lableName2 = 'Backorder DK Archive'
    }
    if (ftp_purpose === 'ConsignmentSampleIDTExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Consignment Sample IDT Export'
      lableName2 = 'Consignment Sample IDT Archive'
    }
    if (ftp_purpose === 'ConsignmentSampleISLExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Consignment Sample ISL Export'
      lableName2 = 'Consignment Sample ISL Archive'
    }

    if (ftp_purpose === 'IDTShipAckModelNExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'IDT Ship Ack ModelN Export'
      lableName2 = 'IDT Ship Ack ModelN Archive'
    }

    if (ftp_purpose === 'ISLShipAckModelNExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'ISL Ship Ack ModelN Export'
      lableName2 = 'ISL Ship Ack ModelN Archive'
    }

    if (ftp_purpose === 'DKShipAckModelNExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'DKShipAckModelNExport'
      lableName2 = 'DK Ship Ack ModelN Archive'
    }
    if (ftp_purpose === 'RELShipAckModelNExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'REL Ship Ack ModelN Export'
      lableName2 = 'REL Ship Ack ModelN Archive'
    }
    if (ftp_purpose === 'TPHShipAckModelNExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'TPH Ship Ack ModelN Export'
      lableName2 = 'TPH Ship Ack ModelN Archive'
    }
    if (ftp_purpose === 'DKShipAckModelNAWSExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'DK Ship Ack ModelN AWS Export'
      lableName2 = 'DK Ship Ack ModelN AWS Archive'
    }
    if (ftp_purpose === 'ISLShipAckModelNAWSExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'ISL Ship Ack ModelN AWS Export'
      lableName2 = 'ISL Ship Ack ModelN AWS Archive'
    }
    if (ftp_purpose === 'IDTShipAckModelNAWSExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'IDT Ship Ack ModelN AWS Export'
      lableName2 = 'IDT Ship Ack ModelN AWS Archive'
    }
    if (ftp_purpose === 'RELShipAckModelNAWSExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'REL Ship Ack ModelN AWS Export'
      lableName2 = 'REL Ship Ack ModelN AWS Archive'
    }
    if (ftp_purpose === 'TPHShipAckModelNAWSExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'TPH Ship Ack ModelN AWS Export'
      lableName2 = 'TPH Ship Ack ModelN AWS Archive'
    }
    if (ftp_purpose === 'ReprocessSampleOrder') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Reprocess Sample Order Export'
      lableName2 = 'Reprocess Sample Order Archive'
    }
    if (ftp_purpose === 'SampleOrderRejectionImport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Sample Order Rejection Import'
      lableName2 = 'Sample Order Rejection Archive'
    }
    if (ftp_purpose === 'ISLAWSExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'ISL AWS Export'
      lableName2 = 'ISL AWS Archive'
    }
    if (ftp_purpose === 'IDTAWSExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'IDT AWS Export'
      lableName2 = 'IDT AWS Archive'
    }
    if (ftp_purpose === 'DKAWSExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'DK AWS Export'
      lableName2 = 'DK AWS Archive'
    }
    if (ftp_purpose === 'SampleOrderAWSExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'SampleOrder AWS Export'
      lableName2 = 'SampleOrder AWS Archive'
    }
    if (ftp_purpose === 'CustomerAWSExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Customer AWS Export'
      lableName2 = 'Customer AWS Archive'
    }
    if (ftp_purpose === 'ConsignmentSampleISLAWSExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Consignment Sample ISL AWS Export'
      lableName2 = 'Consignment Sample ISL AWS Archive'
    }
    if (ftp_purpose === 'ConsignmentSampleIDTAWSExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Consignment Sample IDT AWS Export'
      lableName2 = 'Consignment Sample IDT AWS Archive'
    }
    if (ftp_purpose === 'ConsignmentSampleRELExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Consignment Sample REL Export'
      lableName2 = 'Consignment Sample REL Archive'
    }
    if (ftp_purpose === 'ConsignmentSampleRELAWSExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Consignment Sample REL AWS Export'
      lableName2 = 'Consignment Sample REL AWS Archive'
    }
    if (ftp_purpose === 'ConsignmentSampleTPHExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Consignment Sample TPH Export'
      lableName2 = 'Consignment Sample TPH Archive'
    }
    if (ftp_purpose === 'ConsignmentSampleTPHAWSExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Consignment Sample TPH AWS Export'
      lableName2 = 'Consignment Sample TPH AWS Archive'
    }
    if (ftp_purpose === 'LogNoOrderAck') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Log No Order Ack Folder'
      lableName2 = 'Log No Order Ack Archive'
    }
    if (ftp_purpose === 'LogNoShipAck') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Log No Ship Ack Folder'
      lableName2 = 'Log No Ship Ack Archive'
    }
    if (ftp_purpose === 'LogIncompleteOrders') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Log Incomplete Orders Folder'
      lableName2 = 'Log Incomplete Orders Archive'
    }
    if (ftp_purpose === 'LogOrdersOnDPLHold') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Log Orders On DPL Hold Folder'
      lableName2 = 'Log Orders On DPL Hold Archive'
    }
    if (ftp_purpose === 'LogOrdersWithError') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Log Orders With Error Folder'
      lableName2 = 'Log Orders With Error Archive'
    }
    if (ftp_purpose === 'RELAWSExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Drop order to REL Folder'
      lableName2 = 'Drop order to REL Archive'
    }
    if (ftp_purpose === 'TPHAWSExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'Drop order to TPH Folder'
      lableName2 = 'Drop order to TPH Archive'
    }

    if (ftp_purpose === 'ImportDKCoreReport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'DK CORE Inventory Report Import Folder'
      lableName2 = 'DK CORE Inventory Report Import Archive'
    }
    if (ftp_purpose === 'ImportDKConsignReport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'DK CONSIGN Inventory Report Import Folder'
      lableName2 = 'DK CONSIGN Inventory Report Import Archive'
    }
    if (ftp_purpose === 'ConsignmentSampleIssueOrderS4') {
      archiveFolder = 'ftp_archive_folder'
      lableName2 = 'Consignment Sample Issue Order S4 Import Archive'
    }
    if (ftp_purpose === 'SampleOrderS4') {
      archiveFolder = 'ftp_archive_folder'
      lableName2 = 'Sample Order S4 Import Archive'
    }
    if (ftp_purpose === 'S4OrderAck') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'S4 Order Ack Import'
      lableName2 = 'S4 Order Ack Archive'
    }
    if (ftp_purpose === 'S4ShipAck') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'S4 Ship Ack Import'
      lableName2 = 'S4 Ship Ack Archive'
    }
    if (ftp_purpose === 'GISAWSExport') {
      archiveFolder = 'ftp_archive_folder'
      folderName = 'ftp_folder'
      lableName1 = 'GIS AWS Export'
      lableName2 = 'GIS AWS Export Archive'
    }
    return (
      <div className="formitem_row">
        <Form.Item
          label={lableName1}
          name={folderName}
          rules={[
            {
              required: true,
              message: `Please input your ${lableName1} !`,
            },
          ]}
        >
          <Input placeholder={lableName1} size="large" />
        </Form.Item>
        <Form.Item
          label={lableName2}
          name={archiveFolder}
          rules={[
            {
              required: true,
              message: `Please input your ${lableName2} !`,
            },
          ]}
        >
          <Input placeholder={lableName2} size="large" />
        </Form.Item>
      </div>
    )
  }

  renderFieldsforS4() {
    const ftp_purpose = this.props.ftp_purpose
    let archiveFolder
    let lableName

  }

  async handleChanges({ file, onSuccess }) {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
    this.setState({ file })
  }

  handleChange = (selectedItems) => {
    this.setState({ selectedItems })
  }

  onFinishFailed = (errors) => {
    
    message.error('Enter the required fields!')
  }

  renderForm() {
    const { selectedItems } = this.state
    const ftp_purpose = this.props.ftp_purpose
    const { users } = this.props
    return (
      <div className="ImportSampleOrderStyles">
        <div className="ImportSampleOrder_container">
          <div className="ImportSampleOrder__title">{this.props.tabTitle}</div>
          <div className="layout_content_box ImportSampleOrder_whitebox">
            <Form
              className="form_block_main import-sample-order-form"
              initialValues={this.state.formData}
              onFinish={this.handleFinish}
              onFinishFailed={this.onFinishFailed}
            >
              {this.renderFields()}
              <div className="formitem_row">
                <Form.Item
                  label="Username"
                  name="ftp_username"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your username!',
                    },
                  ]}
                >
                  <Input placeholder="John Doe" size="large" />
                </Form.Item>
                <Form.Item
                  name="email_output_to"
                  label="Select distribution List"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Dstribution group/ Enter MailID!',
                    },
                  ]}
                >
                  <Select
                    className="multiple_select"
                    mode="tags"
                    placeholder="Select Dstribution group or Enter MailID"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    value={selectedItems}
                    onChange={this.handleChange}
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option && option.children
                        ? option.children.indexOf(input.toLowerCase()) >= 0
                        : null
                    }
                    tokenSeparators={[',']}
                  >
                    {users.length &&
                      users.map((item) => (
                        <Select.Option key={item.name} value={item.name}>
                          {item.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Password"
                  name="ftp_password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email!',
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="********"
                    size="large"
                    type="password"
                  />
                </Form.Item>
                <Form.Item
                  label="Start Date"
                  name="start_date"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email!',
                    },
                  ]}
                >
                  <DatePicker placeholder="YYYY-MM-DD" size="large" />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  name="start_time"
                  label="Start Time"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your start_time!',
                    },
                  ]}
                >
                  <TimePicker placeholder="HH:mm:ss" size="large" />
                </Form.Item>
                {ftp_purpose !== 'GISAWSExport' && (
                  <Form.Item
                    label="Frequency"
                    name="frequency"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your frequency!',
                      },
                    ]}
                  >
                    <Select defaultValue="5 Min" style={{ width: '100%' }}>
                      <Option value="5">5 Min</Option>
                      <Option value="15">15 Min</Option>
                      <Option value="30">30 Min</Option>
                      <Option value="45">45 Min</Option>
                      <Option value="60">1 Hour</Option>
                      <Option value="120">2 Hour</Option>
                      <Option value="1440">1 day</Option>
                    </Select>
                  </Form.Item>
                )}
              </div>
              <div className="formitem_row">
                {ftp_purpose == 'ImportDKCoreReport' && (
                  <Form.Item
                    label="IDT DK Inventory Report Notification List"
                    name="IDTEmailNotifications"
                  >
                    <Select
                      className="multiple_select"
                      mode="tags"
                      placeholder="Select Dstribution group or Enter MailID"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      value={selectedItems}
                      onChange={this.handleChange}
                      style={{ width: '100%' }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option && option.children
                          ? option.children.indexOf(input.toLowerCase()) >= 0
                          : null
                      }
                      tokenSeparators={[',']}
                    >
                      {users.length &&
                        users.map((item) => (
                          <Select.Option key={item.name} value={item.name}>
                            {item.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
                {ftp_purpose == 'ImportDKCoreReport' && (
                  <Form.Item
                    label="ISL DK Inventory Report Notification List"
                    name="ISLEmailNotifications"
                  >
                    <Select
                      className="multiple_select"
                      mode="tags"
                      placeholder="Select Dstribution group or Enter MailID"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      value={selectedItems}
                      onChange={this.handleChange}
                      style={{ width: '100%' }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option && option.children
                          ? option.children.indexOf(input.toLowerCase()) >= 0
                          : null
                      }
                      tokenSeparators={[',']}
                    >
                      {users.length &&
                        users.map((item) => (
                          <Select.Option key={item.name} value={item.name}>
                            {item.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
                {ftp_purpose == 'ImportDKConsignReport' && (
                  <Form.Item
                    label="IDT DK Inventory Report Notification List"
                    name="IDTEmailNotifications"
                  >
                    <Select
                      className="multiple_select"
                      mode="tags"
                      placeholder="Select Dstribution group or Enter MailID"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      value={selectedItems}
                      onChange={this.handleChange}
                      style={{ width: '100%' }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option && option.children
                          ? option.children.indexOf(input.toLowerCase()) >= 0
                          : null
                      }
                      tokenSeparators={[',']}
                    >
                      {users.length &&
                        users.map((item) => (
                          <Select.Option key={item.name} value={item.name}>
                            {item.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
                {ftp_purpose == 'ImportDKConsignReport' && (
                  <Form.Item
                    label="ISL DK Inventory Report Notification List"
                    name="ISLEmailNotifications"
                  >
                    <Select
                      className="multiple_select"
                      mode="tags"
                      placeholder="Select Dstribution group or Enter MailID"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      value={selectedItems}
                      onChange={this.handleChange}
                      style={{ width: '100%' }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option && option.children
                          ? option.children.indexOf(input.toLowerCase()) >= 0
                          : null
                      }
                      tokenSeparators={[',']}
                    >
                      {users.length &&
                        users.map((item) => (
                          <Select.Option key={item.name} value={item.name}>
                            {item.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
              </div>
              <div className="formitem_row">
                {ftp_purpose == 'ImportDKConsignReport' && (
                  <Form.Item
                    label="REL DK Inventory Report Notification List"
                    name="RELEmailNotifications"
                  >
                    <Select
                      className="multiple_select"
                      mode="tags"
                      placeholder="Select Dstribution group or Enter MailID"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      value={selectedItems}
                      onChange={this.handleChange}
                      style={{ width: '100%' }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option && option.children
                          ? option.children.indexOf(input.toLowerCase()) >= 0
                          : null
                      }
                      tokenSeparators={[',']}
                    >
                      {users.length &&
                        users.map((item) => (
                          <Select.Option key={item.name} value={item.name}>
                            {item.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
                {ftp_purpose == 'ImportDKCoreReport' && (
                  <Form.Item
                    label="REL DK Inventory Report Notification List"
                    name="RELEmailNotifications"
                  >
                    <Select
                      className="multiple_select"
                      mode="tags"
                      placeholder="Select Dstribution group or Enter MailID"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      value={selectedItems}
                      onChange={this.handleChange}
                      style={{ width: '100%' }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option && option.children
                          ? option.children.indexOf(input.toLowerCase()) >= 0
                          : null
                      }
                      tokenSeparators={[',']}
                    >
                      {users.length &&
                        users.map((item) => (
                          <Select.Option key={item.name} value={item.name}>
                            {item.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}

                {ftp_purpose == 'ImportDKCoreReport' && (
                  <Form.Item
                    label="Email Notification Frequency"
                    name="email_notif_freq"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your frequency!',
                      },
                    ]}
                  >
                    <Select style={{ width: '100%' }}>
                      <Option value="60">1 Hour</Option>
                      <Option value="360">6 Hour</Option>
                      <Option value="720">12 Hour</Option>
                      <Option value="1440">daily</Option>
                    </Select>
                  </Form.Item>
                )}
                {ftp_purpose == 'ImportDKConsignReport' && (
                  <Form.Item
                    label="Email Notification Frequency"
                    name="email_notif_freq"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your frequency!',
                      },
                    ]}
                  >
                    <Select style={{ width: '100%' }}>
                      <Option value="60">1 Hour</Option>
                      <Option value="360">6 Hour</Option>
                      <Option value="720">12 Hour</Option>
                      <Option value="1440">daily</Option>
                    </Select>
                  </Form.Item>
                )}
              </div>
              <div className="formitem_row">
                <Form.Item name="ftp_url" label="URL">
                  <Input placeholder="Enter URL" type="text" />
                </Form.Item>
                {ftp_purpose == 'LogNoOrderAck' && (
                  <Form.Item
                    label=" Allowed delay in Hours"
                    name="allowed_delay"
                    rules={[
                      {
                        required: true,
                        message: 'Please input Allowed delay in Hours!',
                      },
                      // {
                      //     pattern: new RegExp(/^[0-9]$/),
                      //     message: 'Input number in requested format.',
                      //   }
                    ]}
                  >
                    <Input
                      placeholder="Enter Allowed delay in Hours"
                      type="number"
                      pattern="[0-9]"
                      step="1"
                    />
                  </Form.Item>
                )}
                {ftp_purpose == 'LogNoShipAck' && (
                  <Form.Item
                    label=" Allowed delay in Days"
                    name="allowed_delay"
                    rules={[
                      {
                        required: true,
                        message: 'Please input Allowed delay in Days!',
                      },
                      // {
                      //       pattern: new RegExp(/^[0-9]$/),
                      //       message: 'Input number in requested format.',
                      //     }
                    ]}
                  >
                    <Input
                      placeholder="Enter  Allowed delay in Days"
                      type="number"
                      pattern="[0-9]"
                      step="1"
                    />
                  </Form.Item>
                )}
              </div>
              {ftp_purpose !== 'SampleOrderImproperFileFormatsImport' && (
                <div className="formitem_row">
                  <Form.Item
                    name="send_success_logs"
                    label="Send logs for success"
                    valuePropName="checked"
                  >
                    <Checkbox> Send logs for success</Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="api_to_s4"
                    label="Api To S4"
                    valuePropName="checked"
                    hidden={true}
                  >
                    <Switch>Api To S4</Switch>
                  </Form.Item>
                </div>
              )}
              {ftp_purpose !== 'PulseOrderImport' &&
                ftp_purpose !== 'SampleOrderImproperFileFormatsImport' && (
                  <div className="formitem_upload_row">
                    <Form.Item label="Sample Order File">
                      <Form.Item name="dragger">
                        <Upload.Dragger
                          customRequest={(info) => this.handleChanges(info)}
                          accept=".txt"
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">
                            Support for a single or bulk upload.
                          </p>
                        </Upload.Dragger>
                      </Form.Item>
                    </Form.Item>
                  </div>
                )}

              {isPermission(
                //check for save permission
                PERMISSIONS_BY_NAME['Manage FTP settings']
              ) && (
                <div className="formitem_action_row">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      size="large"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>
    )
  }

  render() {
    // return this.state.loader ? (
    //   <div className="loader">
    //     <Spin />
    //   </div>
    // ) : this.state.formData ? (
    //   <div>{this.renderForm()}</div>
    // ) : <Spin />
    return this.state.loader ? (
      <div className="loader">
        <Spin />
      </div>
    ) : (
      <div>{this.renderForm()}</div>
    )
  }
}

export default ImportSampleOrder
