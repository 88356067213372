import React, { Component } from 'react'
import {
  Table,
  Layout,
  Form,
  Input,
  Button,
  Breadcrumb,
  Select,
  Upload,
  DatePicker,
  TimePicker,
  message,
  Spin,
  Tabs,
} from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import './FTSSettings.scss'
import ImportCatalog from '../../pages/ImportCatalog/ImportCatalog'
import { withRouter } from 'react-router-dom'
import ImportSampleOrder from '../../pages/ImportSampleOrder/ImportSampleOrder'
import ImportSampleOrderforS4 from '../../pages/ImportSampleOrder/ImportSampleOrderforS4'
import ImportS4Ack from '../../pages/ImportSampleOrder/ImportS4Ack'
import ExportSampleOrder from '../../pages/ExportSampleOrder/ExportSampleOrder'
import ImportSampleOrderApproval from '../../pages/ImportSampleOrderApproval/ImportSampleOrderApproval'
import HistoryTable from '../../components/HistoryTable/HistoryTable'
import { HTTPService } from '../../otherServices/apiService'
import moment from 'moment'
import { DATE_FORMAT, TIME_FORMAT } from '../../otherServices/constant'
import { PAGE_LIMIT, USER_SORTING } from '../../otherServices/constant'
const { Sider, Content } = Layout

const { Option } = Select
const { TabPane } = Tabs

class FTSSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableData: [],
      loader: false,
      titleName: 'Catalog Import History',
      users: [],
      isDistributionData: false,
    }
  }

  componentDidMount() {
    this.fetchTableData()
    this.fetDistributionList()
  }

  //function fetch distribution list for form
  async fetDistributionList() {
    let userRes
    try {
      userRes = await HTTPService({
        method: 'GET',
        url: `distribution-list/?ordering=-id&limit=${PAGE_LIMIT}&offset=0&${USER_SORTING}`,
      })
      if (userRes.success) {
        this.setState({ users: userRes.data.results, isDistributionData: true })
      } else {
        this.setState({ isDistributionData: false })
      }
    } catch (ex) {
      this.setState({ isDistributionData: false })
    }
  }

  //function to fetch table data
  async fetchTableData(val) {
    this.setState({ loader: true })
    let formKey = 'CatalogImport'
    let titleName = 'Catalog Import History'
    if (val == '1') {
      formKey = 'CatalogImport'
      titleName = 'Catalog Import History'
    }
    if (val == '2') {
      formKey = 'SampleOrderImport'
      titleName = 'Sample Order Import History'
    }
    if (val == '3') {
      formKey = 'SampleOrderApprovalImport'
      titleName = 'Sample Order Approval Import History'
    }
    if (val == '4') {
      formKey = 'SampleOrderExport'
      titleName = 'Sample Order Export History'
    }
    if (val == '5') {
      formKey = 'CustomerExport'
      titleName = 'Customer Export History'
    }
    if (val == '6') {
      formKey = 'ISLImport'
      titleName = 'ISL Import History'
    }
    if (val == '7') {
      formKey = 'ISLExport'
      titleName = 'ISL Export History'
    }
    if (val == '8') {
      formKey = 'IDTImport'
      titleName = 'IDT Import History'
    }
    if (val == '9') {
      formKey = 'IDTExport'
      titleName = 'IDT Export History'
    }
    if (val == '10') {
      formKey = 'DKImport'
      titleName = 'DK Import History'
    }
    if (val == '11') {
      formKey = 'DKExport'
      titleName = 'DK Export History'
    }
    if (val == '14') {
      formKey = 'ShipmentAckDKImport'
      titleName = 'Shipment Ack DK Import'
    }
    if (val == '15') {
      formKey = 'ShipmentAckISLImport'
      titleName = 'Shipment Ack ISL Import'
    }
    if (val == '16') {
      formKey = 'ShipmentAckIDTImport'
      titleName = 'Shipment Ack IDT Import'
    }
    if (val == '17') {
      formKey = 'ErrorOrderDKImport'
      titleName = 'Error Order DK Import'
    }
    if (val == '18') {
      formKey = 'ErrorOrderIDTImport'
      titleName = 'Error Order IDT Import'
    }
    if (val == '19') {
      formKey = 'ErrorOrderISLImport'
      titleName = 'Error Order ISL Import'
    }

    if (val == '20') {
      formKey = 'DuplicateOrderDKImport'
      titleName = 'Duplicate Order DK Import'
    }
    if (val == '21') {
      formKey = 'DuplicateOrderIDTImport'
      titleName = 'Duplicate Order IDT Import'
    }
    if (val == '22') {
      formKey = 'DuplicateOrderISLImport'
      titleName = 'Duplicate Order ISL Import'
    }
    if (val == '23') {
      formKey = 'BackorderDKImport'
      titleName = 'Backorder DK Import'
    }
    if (val == '24') {
      formKey = 'ConsignmentSampleIDTExport'
      titleName = 'Consignment Sample IDT Export'
    }
    if (val == '25') {
      formKey = 'ConsignmentSampleISLExport'
      titleName = 'Consignment Sample ISL Export'
    }
    if (val == '26') {
      formKey = 'IDTShipAckModelNExport'
      titleName = 'IDT Ship Ack ModelN Export'
    }
    if (val == '27') {
      formKey = 'ISLShipAckModelNExport'
      titleName = 'ISL Ship AckModelN Export'
    }
    if (val == '28') {
      formKey = 'DKShipAckModelNExport'
      titleName = 'DK Ship Ack ModelN Export'
    }
    if (val == '45') {
      formKey = 'RELShipAckModelNExport'
      titleName = 'REL Ship Ack ModelN Export'
    }
    if (val == '46') {
      formKey = 'DKShipAckModelNAWSExport'
      titleName = 'DK Ship Ack ModelN AWS Export'
    }
    if (val == '47') {
      formKey = 'ISLShipAckModelNAWSExport'
      titleName = 'ISL Ship Ack ModelN AWS Export'
    }
    if (val == '48') {
      formKey = 'IDTShipAckModelNAWSExport'
      titleName = 'IDT Ship Ack ModelN AWS Export'
    }
    if (val == '49') {
      formKey = 'RELShipAckModelNAWSExport'
      titleName = 'REL Ship Ack ModelN AWS Export'
    }
    if (val == '50') {
      formKey = 'ReprocessSampleOrder'
      titleName = 'Reprocess Sample Order'
    }

    if (val == '51') {
      formKey = 'DKCOREInventoryReportImport'
      titleName = 'DK CORE Inventory Report Import'
    }
    if (val == '52') {
      formKey = 'DKCONSIGNInventoryReportImport'
      titleName = 'DK CONSIGN Inventory Report Import'
    }

    if (val == '29') {
      formKey = 'SampleOrderRejectionImport'
      titleName = 'Order Import from ModelN (Rejection)'
    }
    if (val == '30') {
      formKey = 'ISLAWSExport'
      titleName = 'ISL AWS Export'
    }
    if (val == '31') {
      formKey = 'IDTAWSExport'
      titleName = 'IDT AWS Export'
    }
    if (val == '32') {
      formKey = 'DKAWSExport'
      titleName = 'DK AWS Export'
    }
    if (val == '33') {
      formKey = 'SampleOrderAWSExport'
      titleName = 'SampleOrder AWS Export'
    }
    if (val == '34') {
      formKey = 'CustomerAWSExport'
      titleName = 'Customer AWS Export'
    }
    if (val == '35') {
      formKey = 'Consignment Sample ISL AWS Export'
      titleName = 'SampleOrder AWS Export'
    }
    if (val == '36') {
      formKey = 'ConsignmentSampleIDTAWSExport'
      titleName = 'Consignment Sample IDT AWS Export'
    }
    if (val == '37') {
      formKey = 'ConsignmentSampleRELExport'
      titleName = 'Consignment Sample REL Export'
    }
    if (val == '38') {
      formKey = 'ConsignmentSampleRELAWSExport'
      titleName = 'Consignment Sample RELAWS Export'
    }
    if (val == '39') {
      formKey = 'LogNoOrderAck'
      titleName = 'Log No Order Ack'
    }
    if (val == '40') {
      formKey = 'LogNoShipAck'
      titleName = 'Log No Ship Ack'
    }
    if (val == '41') {
      formKey = 'LogIncompleteOrders'
      titleName = 'Log Incomplete Orders'
    }
    if (val == '42') {
      formKey = 'LogOrdersOnDPLHold'
      titleName = 'Log Orders On DPL Hold'
    }
    if (val == '43') {
      formKey = 'LogOrdersWithError'
      titleName = 'Log Orders With Error'
    }
    if (val == '44') {
      formKey = 'RELAWSExport'
      titleName = 'Drop order to REL'
    }
    if (val == '53') {
      formKey = 'ConsignmentSampleIssueOrderS4'
      titleName = 'Consignment Sample Issue Order S4'
    }
    if (val == '54') {
      formKey = 'SampleOrderToS4'
      titleName = 'Sample Order S4'
    }
    if (val == '55') {
      formKey = 'S4OrderAck'
      titleName = 'S4 Order Ack'
    }
    if (val == '56') {
      formKey = 'S4ShipAck'
      titleName = 'S4 Ship Ack'
    }
    if (val == '57') {
      formKey = 'PulseOrderImport'
      titleName = 'Pulse Order Import History'
    }
    if (val === '58') {
      formKey = 'PulseOrderError'
      titleName = 'Pluse Order Error'
    }
    if (val === '59') {
      formKey = 'GISAWSExport'
      titleName = 'GIS AWS Export History'
    }
    if (val === '60') {
      formKey = 'PulseOrderSuccess'
      titleName = 'Pluse Order Success'
    }
    if (val === '61') {
      formKey = 'TPHAWSExport'
      titleName = 'Drop TPH AWS Export'
    }
    if (val === '62') {
      formKey = 'TPHShipAckModelNExport'
      titleName = 'TPH Ship Ack ModelN Export'
    }
    if (val === '63') {
      formKey = 'ConsignmentSampleTPHExport'
      titleName = 'Consignment Sample TPH Export'
    }
    if (val === '64') {
      formKey = 'ConsignmentSampleTPHAWSExport'
      titleName = 'Consignment Sample TPH AWS Export'
    }
    if (val === '65') {
      formKey = 'TPHShipAckModelNAWSExport'
      titleName = 'TPH Ship Ack ModelN AWS Export'
    }
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: `order-history/?search=${formKey}&ordering=-id`,
      })
      if (userRes.success) {
        // message.success(userRes.message)
        this.setState({
          tableData: userRes.data.results,
          loader: false,
          titleName,
        })
      } else {
        message.error(userRes.message)
      }
    } catch (error) {
      this.setState({ loader: false })
      message.error('Failed load data', error)
    }
  }

  render() {
    let fetchedData = this.state.tableData
    fetchedData.map((i) => {
      i.date = moment(i.created_at).format(DATE_FORMAT)
      i.time = moment(i.created_at).format(TIME_FORMAT)
      return i
    })

    return (
      <div className="FTSSettingsStyles">
        <Layout className="site-layout">
          <Content>
            <div className="layout_middel_content">
              <Breadcrumb>
                <Breadcrumb.Item>FTP Settings</Breadcrumb.Item>
              </Breadcrumb>
              <Tabs
                className="FTSSettings_Tabs"
                defaultActiveKey="1"
                onChange={(val) => {
                  this.fetchTableData(val)
                }}
              >
                <TabPane tab="Catalog Import" key="1">
                  <ImportSampleOrder
                    ftp_purpose="CatalogImport"
                    tabTitle="Catalog Import"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Order Import from Renesas" key="2">
                  <ImportSampleOrder
                    ftp_purpose="SampleOrderImport"
                    tabTitle="Order Import from Renesas"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Order Import from ModelN (Approval)" key="3">
                  <ImportSampleOrder
                    ftp_purpose="SampleOrderApprovalImport"
                    tabTitle="Order Import from ModelN (Approval)"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Order Import from ModelN (Rejection)" key="29">
                  <ImportSampleOrder
                    ftp_purpose="SampleOrderRejectionImport"
                    tabTitle="Order Import from ModelN (Rejection)"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Order export to ModelN" key="4">
                  <ImportSampleOrder
                    ftp_purpose="SampleOrderExport"
                    tabTitle="Order Export to ModelN"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Customer Export to ModelN" key="5">
                  <ImportSampleOrder
                    ftp_purpose="CustomerExport"
                    tabTitle="Customer Export to ModelN"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Order ack from ISL" key="6">
                  <ImportSampleOrder
                    ftp_purpose="ISLImport"
                    tabTitle="Order ack from ISL"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Drop order to ISL" key="7">
                  <ImportSampleOrder
                    ftp_purpose="ISLExport"
                    tabTitle="Drop order to ISL"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Order ack from IDT" key="8">
                  <ImportSampleOrder
                    ftp_purpose="IDTImport"
                    tabTitle="Order ack from IDT"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Drop order to IDT" key="9">
                  <ImportSampleOrder
                    ftp_purpose="IDTExport"
                    tabTitle="Drop order to IDT"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Order ack from DK" key="10">
                  <ImportSampleOrder
                    ftp_purpose="DKImport"
                    tabTitle="Order ack from DK"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Drop order to DK" key="11">
                  <ImportSampleOrder
                    ftp_purpose="DKExport"
                    tabTitle="Drop order to DK"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Shipment ACK DK" key="14">
                  <ImportSampleOrder
                    ftp_purpose="ShipmentAckDKImport"
                    tabTitle="Shipment ACK DK"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Shipment ACK ISL" key="15">
                  <ImportSampleOrder
                    ftp_purpose="ShipmentAckISLImport"
                    tabTitle="Shipment ACK DK ISL"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Shipment ACK IDT" key="16">
                  <ImportSampleOrder
                    ftp_purpose="ShipmentAckIDTImport"
                    tabTitle="Shipment ACK IDT"
                    users={this.state.users}
                  />
                </TabPane>

                <TabPane tab="Error Order DK" key="17">
                  <ImportSampleOrder
                    ftp_purpose="ErrorOrderDKImport"
                    tabTitle="Error Order DK"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Error Order IDT" key="18">
                  <ImportSampleOrder
                    ftp_purpose="ErrorOrderIDTImport"
                    tabTitle="Error Order IDT"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Error Order ISL" key="19">
                  <ImportSampleOrder
                    ftp_purpose="ErrorOrderISLImport"
                    tabTitle="Error Order ISL"
                    users={this.state.users}
                  />
                </TabPane>

                <TabPane tab="Duplicate Order DK" key="20">
                  <ImportSampleOrder
                    ftp_purpose="DuplicateOrderDKImport"
                    tabTitle="Duplicate Order DK"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Duplicate Order IDT" key="21">
                  <ImportSampleOrder
                    ftp_purpose="DuplicateOrderIDTImport"
                    tabTitle="Duplicate Order IDT"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Duplicate Order ISL" key="22">
                  <ImportSampleOrder
                    ftp_purpose="DuplicateOrderISLImport"
                    tabTitle="Duplicate Order ISL"
                    users={this.state.users}
                  />
                </TabPane>

                <TabPane tab="Backorder DK" key="23">
                  <ImportSampleOrder
                    ftp_purpose="BackorderDKImport"
                    tabTitle="Backorder DK"
                    users={this.state.users}
                  />
                </TabPane>

                <TabPane tab="Consignment Sample IDT Export" key="24">
                  <ImportSampleOrder
                    ftp_purpose="ConsignmentSampleIDTExport"
                    tabTitle="Consignment Sample IDT Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Consignment Sample ISL Export" key="25">
                  <ImportSampleOrder
                    ftp_purpose="ConsignmentSampleISLExport"
                    tabTitle="Consignment Sample ISL Export"
                    users={this.state.users}
                  />
                </TabPane>

                <TabPane tab="IDT Ship Ack ModelN Export" key="26">
                  <ImportSampleOrder
                    ftp_purpose="IDTShipAckModelNExport"
                    tabTitle="IDT Ship Ack ModelN Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="ISL Ship AckModelN Export" key="27">
                  <ImportSampleOrder
                    ftp_purpose="ISLShipAckModelNExport"
                    tabTitle="ISL Ship AckModelN Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="DK Ship Ack ModelN Export" key="28">
                  <ImportSampleOrder
                    ftp_purpose="DKShipAckModelNExport"
                    tabTitle="DK Ship Ack ModelN Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="REL Ship Ack ModelN Export" key="45">
                  <ImportSampleOrder
                    ftp_purpose="RELShipAckModelNExport"
                    tabTitle="REL Ship Ack ModelN Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="DK Ship Ack ModelN AWS Export" key="46">
                  <ImportSampleOrder
                    ftp_purpose="DKShipAckModelNAWSExport"
                    tabTitle="DK Ship Ack ModelN AWS Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="ISL Ship Ack ModelN AWS Export" key="47">
                  <ImportSampleOrder
                    ftp_purpose="ISLShipAckModelNAWSExport"
                    tabTitle="ISL Ship Ack ModelN AWS Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="IDT Ship Ack ModelN AWS Export" key="48">
                  <ImportSampleOrder
                    ftp_purpose="IDTShipAckModelNAWSExport"
                    tabTitle="IDT Ship Ack ModelN AWS Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="REL Ship Ack ModelN AWS Export" key="49">
                  <ImportSampleOrder
                    ftp_purpose="RELShipAckModelNAWSExport"
                    tabTitle="REL Ship Ack ModelN AWS Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Reprocess Sample Order" key="50">
                  <ImportSampleOrder
                    ftp_purpose="ReprocessSampleOrder"
                    tabTitle="Reprocess Sample Order"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="ISL AWS Export" key="30">
                  <ImportSampleOrder
                    ftp_purpose="ISLAWSExport"
                    tabTitle="ISL AWS Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="IDT AWS Export" key="31">
                  <ImportSampleOrder
                    ftp_purpose="IDTAWSExport"
                    tabTitle="IDT AWS Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="DK AWS Export" key="32">
                  <ImportSampleOrder
                    ftp_purpose="DKAWSExport"
                    tabTitle="DK AWS Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="SampleOrder AWS Export" key="33">
                  <ImportSampleOrder
                    ftp_purpose="SampleOrderAWSExport"
                    tabTitle="SampleOrder AWS Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Customer AWS Export" key="34">
                  <ImportSampleOrder
                    ftp_purpose="CustomerAWSExport"
                    tabTitle="Customer AWS Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Consignment Sample ISL AWS Export" key="35">
                  <ImportSampleOrder
                    ftp_purpose="ConsignmentSampleISLAWSExport"
                    tabTitle="Consignment Sample ISL AWS Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Consignment Sample IDT AWS Export" key="36">
                  <ImportSampleOrder
                    ftp_purpose="ConsignmentSampleIDTAWSExport"
                    tabTitle="Consignment Sample IDT AWS Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Consignment Sample REL Export" key="37">
                  <ImportSampleOrder
                    ftp_purpose="ConsignmentSampleRELExport"
                    tabTitle="Consignment Sample REL Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Consignment Sample REL AWS Export" key="38">
                  <ImportSampleOrder
                    ftp_purpose="ConsignmentSampleRELAWSExport"
                    tabTitle="Consignment Sample REL AWS Export"
                    users={this.state.users}
                  />
                </TabPane>

                <TabPane tab="Log No Order Ack" key="39">
                  <ImportSampleOrder
                    ftp_purpose="LogNoOrderAck"
                    tabTitle="Log No Order Ack"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Log No Ship Ack" key="40">
                  <ImportSampleOrder
                    ftp_purpose="LogNoShipAck"
                    tabTitle="Log No Ship Ack"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Log Incomplete Orders" key="41">
                  <ImportSampleOrder
                    ftp_purpose="LogIncompleteOrders"
                    tabTitle="Log Incomplete Orders"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Log Orders On DPL Hold" key="42">
                  <ImportSampleOrder
                    ftp_purpose="LogOrdersOnDPLHold"
                    tabTitle="Log Orders On DPL Hold"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Log Orders With Error" key="43">
                  <ImportSampleOrder
                    ftp_purpose="LogOrdersWithError"
                    tabTitle="Log Orders With Error"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Drop REL AWS Export" key="44">
                  <ImportSampleOrder
                    ftp_purpose="RELAWSExport"
                    tabTitle="Drop order to REL"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="DK CORE Inventory Report Import" key="51">
                  <ImportSampleOrder
                    ftp_purpose="ImportDKCoreReport"
                    tabTitle="DK CORE Inventory Report Import"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="DK CONSIGN Inventory Report Import" key="52">
                  <ImportSampleOrder
                    ftp_purpose="ImportDKConsignReport"
                    tabTitle="DK CONSIGN Inventory Report Import"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Consignment Sample Issue Order S4" key="53">
                  <ImportSampleOrderforS4
                    ftp_purpose="ConsignmentSampleIssueOrderS4"
                    tabTitle="Consignment Sample Issue Order S4"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Sample Order S4" key="54">
                  <ImportSampleOrderforS4
                    ftp_purpose="SampleOrderToS4"
                    tabTitle="Sample Order S4"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="S4 Order Ack" key="55">
                  <ImportS4Ack
                    ftp_purpose="S4OrderAck"
                    tabTitle="S4 Order Ack"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="S4 Ship Ack" key="56">
                  <ImportS4Ack
                    ftp_purpose="S4ShipAck"
                    tabTitle="S4 Ship Ack"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Pulse Order Import" key="57">
                  <ImportSampleOrder
                    ftp_purpose="PulseOrderImport"
                    tabTitle="PulseOrder Import"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Pulse Order Error" key="58">
                  <ImportSampleOrder
                    ftp_purpose="PulseOrderError"
                    tabTitle="Pulse Order Error"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Pulse Order Success" key="60">
                  <ImportSampleOrder
                    ftp_purpose="PulseOrderSuccess"
                    tabTitle="Pulse Order Success"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="GIS AWS Export" key="59">
                  <ImportSampleOrder
                    ftp_purpose="GISAWSExport"
                    tabTitle="GIS AWS Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Drop TPH AWS Export" key="61">
                  <ImportSampleOrder
                    ftp_purpose="TPHAWSExport"
                    tabTitle="Drop TPH AWS Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="TPH Ship Ack ModelN Export" key="62">
                  <ImportSampleOrder
                    ftp_purpose="TPHShipAckModelNExport"
                    tabTitle="TPH Ship Ack ModelN Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Consignment Sample TPH Export" key="63">
                  <ImportSampleOrder
                    ftp_purpose="ConsignmentSampleTPHExport"
                    tabTitle="Consignment Sample TPH Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="Consignment Sample TPH AWS Export" key="64">
                  <ImportSampleOrder
                    ftp_purpose="ConsignmentSampleTPHAWSExport"
                    tabTitle="Consignment Sample TPH AWS Export"
                    users={this.state.users}
                  />
                </TabPane>
                <TabPane tab="TPH Ship Ack ModelN AWS Export" key="65">
                  <ImportSampleOrder
                    ftp_purpose="TPHShipAckModelNAWSExport"
                    tabTitle="TPH Ship Ack ModelN AWS Export"
                    users={this.state.users}
                  />
                </TabPane>
              </Tabs>
              {this.state.loader && this.state.isDistributionData ? (
                <div className="loader">
                  {' '}
                  <Spin />{' '}
                </div>
              ) : (
                <div>
                  {' '}
                  <HistoryTable
                    titleName={this.state.titleName}
                    data={fetchedData}
                    row
                  />
                </div>
              )}
            </div>
          </Content>
        </Layout>
      </div>
    )
  }
}

export default withRouter(FTSSettings)
