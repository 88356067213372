import React, { useContext, useState, useEffect, useRef, Fragment } from 'react'
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  message,
  Spin,
  Space,
  Popover,
  Tooltip,
} from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import './OrderTableEdit.scss'
import '../CustomModal/CustomModal.scss'
import { HTTPService } from '../../otherServices/apiService'
import OrderForm from './OrderForm'
import {
  exportCSVFile,
  trimSpace,
  downloadExcel,
  isPermission,
} from '../../otherServices/commonFunction'
import {
  PAGE_LIMIT,
  PERMISSIONS_BY_NAME,
  ORDER_SORTING,
} from '../../otherServices/constant'
import SampleRoreOrderAudit from '../SampleOrderAudit/SampleRoreOrderAudit'

const { Search } = Input

let selectSampleOrders = []
const EditableContext = React.createContext()
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef()
  const form = useContext(EditableContext)
  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    })
  }

  const save = async (e) => {
    try {
      const values = await form.validateFields()
      toggleEdit()
      handleSave({ ...record, ...values })
    } catch (errInfo) {}
  }

  let childNode = children

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 20,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    )
  }

  return <td {...restProps}>{childNode}</td>
}

class OrderTableEdit extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
  }

  //function for search functionaliity in table columns
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
      <div className="search_filterDropdown">
        <Input
          className="search_input"
          ref={(node) => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            clearFilters()
            this.onSearch(`${dataIndex}=${selectedKeys[0].trim()}`)
          }}
        />
        <Space className="btns">
          <Button
            type="primary"
            onClick={() => {
              clearFilters()
              this.onSearch(`${dataIndex}=${selectedKeys[0].trim()}`)
            }}
            icon={<SearchOutlined />}
            size="medium"
          >
            Search
          </Button>
          <Button
            onClick={() => {
              // clearFilters()
              this.onFilterReset(`${dataIndex}`)
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#3653AA' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#fafafa', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  //search function
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }

  //function to reset filter
  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '', filterValueArray: [] })
  }

  constructor(props) {
    super(props)

    this.state = {
      count: 2,
      tableData: [],
      isFormOpen: false,
      loader: false,
      currentOrder: null,
      currentPage: 1,
      count: 0,
      searchText: '',
      filterApplied: null,
      selectedOrderIds: [],
      isUser: true,
      filterValueArray: [],
      isFilter: false,
      filterString: '',
      pageLimit: 20,
      isAddPart: false,
      isAuditLog: false,
      selectedallRoreOrder: [],
      selectAllExportData: [],
      isFilterText: false,
      highlightFilterArray: [],
      loadingExportData: false,
      selectedRoreOrderID: [],
    }
    this.getOrderList = this.getOrderList.bind(this)
  }

  //function to toggle flag for Add/Edit form
  setFormStatus = (order, mode) => {
    if (mode == 'edit') {
      this.setState({ isAddPart: false })
    } else {
      this.setState({ isAddPart: true })
    }
    this.setState(
      {
        isFormOpen: !this.state.isFormOpen,
        currentOrder: order ? order : null,
      },
      () => localStorage.setItem('savedState', JSON.stringify(this.state))
    )
  }

  //function to toggle flag for Audit log form
  setAuditLogStatus = (isAuditLog, isFormOpen) => {
    if (
      this.state.selectedRoreOrder &&
      this.state.selectedRoreOrder.length === 0
    ) {
      message.info('You need to select atleast 1 Rore Order to see Audit Log')
      return
    }
    if (
      this.state.selectedRoreOrder &&
      this.state.selectedRoreOrder.length > 1
    ) {
      message.error('You can select only 1 Rore Order to see Audit Log')
      return
    }
    this.setState({
      isFormOpen: isFormOpen,
      isAuditLog: isAuditLog,
    })
  }

  componentDidMount() {
    let role = localStorage.getItem('role')
    const savedState = JSON.parse(localStorage.getItem('savedState'))

    if (savedState) {
      this.setState(savedState)
      return
    }
    if (role == 'System Admin') {
      this.setState({ isUser: false })
    }
    if (this.state.isFilter) {
      this.getFilteredData(this.state.filterString)
    } else {
      this.getOrderList('')
    }
  }

  // componentWillUnmount() {
  //   localStorage.removeItem('savedState')
  // }
  //search fumnction
  onSearch = (value = '') => {
    let arrayOfFilter = this.state.filterValueArray
    arrayOfFilter.filter((i, index) => {
      let stringSplit = value.split('=')
      if (i.includes(stringSplit[0])) {
        arrayOfFilter.splice(index, 1)
        return i
      }
    })
    arrayOfFilter.push(value)
    let highlightArray = []
    let isFilterColor = arrayOfFilter.map((i) => {
      highlightArray.push(i.split('=')[0])
    })
    this.setState({
      filterValueArray: arrayOfFilter,
      isFilter: true,
      filterString: `${arrayOfFilter.join('&&')}`,
      highlightFilterArray: highlightArray,
    })
    this.getFilteredData(`${arrayOfFilter.join('&&')}`)
  }

  //function to reset applied filters
  onFilterReset = (value = '') => {
    let arrayOfFilter = this.state.filterValueArray
    arrayOfFilter.filter((i, index) => {
      if (i.includes(value)) {
        arrayOfFilter.splice(index, 1)
        return i
      }
    })
    let highlightArray = []
    let isFilterColor = arrayOfFilter.map((i) => {
      highlightArray.push(i.split('=')[0])
    })

    this.setState({
      filterValueArray: arrayOfFilter,
      isFilter: true,
      highlightFilterArray: highlightArray,
    })
    if (arrayOfFilter.length == 0) {
      this.getOrderList('')
    } else {
      this.setState({ filterString: `${arrayOfFilter.join('&&')}` })
      this.getFilteredData(`${arrayOfFilter.join('&&')}`)
    }
  }

  //function to fetch table data
  async getOrderList(searchValue = '', currentPage = 1) {
    let offset = (currentPage - 1) * this.state.pageLimit
    let urlLink = `sampleorder/?${ORDER_SORTING}&limit=${
      this.state.pageLimit
    }&offset=${offset}${searchValue && { searchValue }}`
    this.setState({ loader: true, highlightFilterArray: [] })
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: urlLink,
      })
      if (userRes.success) {
        let orders = []
        for (let o of userRes.data.results) {
          orders.push({ ...o, key: o.id })
        }
        this.setState({
          tableData: orders,
          loader: false,
          count: userRes.data.count,
          searchText: searchValue || null,
          currentPage,
          filterApplied: searchValue,
          filterValueArray: [],
          isFilter: false,
          selectedRoreOrder: [],
        })
        message.success('Fetched Successfully')
      } else {
        this.setState({
          tableData: [],
          loader: false,
          count: 0,
          searchText: searchValue || null,
          currentPage,
        })
        message.error(userRes.message)
      }
    } catch (error) {
      message.error('Response Failed')
      this.setState({
        tableData: [],
        loader: false,
        count: 0,
        searchText: searchValue || null,
        currentPage,
      })
    }
  }

  //function to fetch filtered data
  async getFilteredData(searchValue = '', currentPage = 1) {
    let urlLink = `sampleorder/?${ORDER_SORTING}&${searchValue}`
    this.setState({ loader: true })
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: urlLink,
      })
      if (userRes.success) {
        let orders = []
        for (let o of userRes.data.results) {
          orders.push({ ...o, key: o.id })
        }

        this.setState({
          tableData: orders,
          loader: false,
          count: userRes.data.count,
          searchText: searchValue || null,
          currentPage,
          filterApplied: searchValue,
        })
        message.success('Filter Applied')
      } else {
        this.setState({ loader: false })
        message.error(userRes.message)
      }
    } catch (error) {
      message.error('Filter Not Applied')
    }
  }

  //function to delete record
  handleDelete = async (key) => {
    try {
      await HTTPService({
        url: `sampleorder/${key}/`,
        method: 'DELETE',
      })
      message.success('Record Deleted')
      this.getOrderList('', this.state.currentPage)
    } catch (ex) {
      if (ex.response.status === 500) message.error('Internal Server Error')
    }
  }

  handleSave = (row) => {
    const newData = [...this.state.dataSource]
    const index = newData.findIndex((item) => row.key === item.key)
    const item = newData[index]
    newData.splice(index, 1, { ...item, ...row })
    this.setState({
      dataSource: newData,
    })
  }

  //function to fetch table data on page change
  onChangePageNumber = async (pageNumber, PAGE_LIMIT) => {
    this.setState({
      loader: true,
      pageLimit: PAGE_LIMIT,
      selectedRoreOrder: [],
    })
    let offset = (pageNumber - 1) * PAGE_LIMIT
    try {
      let sampleOrderRes
      if (this.state.isFilter) {
        sampleOrderRes = await HTTPService({
          method: 'GET',
          url: `sampleorder/?${this.state.searchText}&${ORDER_SORTING}&limit=${PAGE_LIMIT}&offset=${offset}`,
        })
      } else {
        sampleOrderRes = await HTTPService({
          method: 'GET',
          url: `sampleorder/?${ORDER_SORTING}&limit=${PAGE_LIMIT}&offset=${offset}`,
        })
      }

      if (sampleOrderRes.success) {
        let orders = [],
          count = 0
        for (let order of sampleOrderRes.data.results) {
          orders.push({ ...order, key: order.id })
        }

        count = sampleOrderRes.data.count
        this.setState({
          tableData: orders,
          count,
          currentPage: pageNumber,
          currentOrder: null,
          isFormOpen: false,
          loader: false,
        })
      } else {
        message.error(sampleOrderRes.message)
        this.setState({
          isFormOpen: false,
          loader: false,
        })
      }
    } catch {
      message.error('Failed to Fetch')
      this.setState({
        isFormOpen: false,
        loader: false,
      })
    }
  }
  //function to Reprocess
  handleReprocess = async () => {
    if (this.state.selectedRoreOrder.length > 0) {
      let rore_order = this.state.selectedRoreOrder
      this.setState({ loader: true })
      try {
        let userRes = await HTTPService({
          method: 'POST',
          url: `order-reprocess/`,
          data: {
            rore_order,
          },
        })
        if (userRes.success) {
          message.success('Reprocess succeed')
          this.getOrderList('', this.state.currentPage)
          this.setState({ loader: false })
        } else {
          message.error(userRes.message)
          this.setState({ loader: false })
        }
      } catch (error) {
        message.error('Reprocesse Failed')
        this.setState({ loader: false })
      }
    } else message.info('Please select a sample order')
  }

  //function to Reprocess by pass
  handleReprocessBypass = async () => {
    if (this.state.selectedRoreOrder.length > 0) {
      let rore_order = this.state.selectedRoreOrder
      this.setState({ loader: true })
      try {
        let userRes = await HTTPService({
          method: 'POST',
          url: `order-reprocess-bypass/`,
          data: {
            rore_order,
          },
        })
        if (userRes.success) {
          message.success('Reprocess Bypass Disti succeed')
          this.getOrderList('', this.state.currentPage)
          this.setState({ loader: false })
        } else {
          message.error(userRes.message)
          this.setState({ loader: false })
        }
      } catch (error) {
        message.error(' Reprocesse Bypass Disti Failed')
        this.setState({ loader: false })
      }
    } else message.info('Please select a sample order')
  }

  //function to display total item
  showTotal = () => {
    return `Total ${this.state.count}`
  }

  //function to massrelease
  handleMassRelease = async () => {
    if (this.state.selectedRoreOrder.length > 0) {
      let selectedRoreOrder = this.state.selectedRoreOrder
      let dataObj = {
        rore_orders: this.state.tableData
          .map((td) => {
            if (selectedRoreOrder.includes(td.id)) {
              return td.rore_order
            }
            return false
          })
          .filter(Boolean),
      }

      try {
        let userRes = await HTTPService({
          method: 'POST',
          url: `sampleorder-release/`,
          data: {
            ...dataObj,
          },
        })
        if (userRes.success) {
          message.success('Mass Released Successfully.')
          this.setState({ loader: false })
          this.getOrderList('', this.state.currentPage)
        } else {
          message.error(userRes.message)
          this.setState({ loader: false })
          this.getOrderList('', this.state.currentPage)
        }
      } catch (error) {
        this.getOrderList('', this.state.currentPage)
        message.error(' Mass Release Failed')
        this.setState({ loader: false })
      }
    } else message.info('Please select a sample order')
  }

  handleEusholdRelease = async () => {
    if (this.state.selectedRoreOrder.length > 0) {
      let selectedRoreOrder = this.state.selectedRoreOrder
      let dataObj = {
        rore_orders: this.state.tableData
          .map((td) => {
            if (selectedRoreOrder.includes(td.id)) {
              return td.rore_order
            }
            return false
          })
          .filter(Boolean),
      }

      try {
        let userRes = await HTTPService({
          method: 'POST',
          url: `sampleorder-eus-hold-release/`,
          data: {
            ...dataObj,
          },
        })
        if (userRes.success) {
          message.success('Eus hold Released Successfully.')
          this.setState({ loader: false })
          this.getOrderList('', this.state.currentPage)
        } else {
          message.error(userRes.message)
          this.setState({ loader: false })
          this.getOrderList('', this.state.currentPage)
        }
      } catch (error) {
        this.getOrderList('', this.state.currentPage)
        message.error('Eus hold Release Failed')
        this.setState({ loader: false })
      }
    } else message.info('Please select a sample order')
  }

  //function to MassCancel
  handleMassCancel = async () => {
    if (this.state.selectedRoreOrder.length > 0) {
      let selectedRoreOrder = this.state.selectedRoreOrder
      let dataObj = {
        rore_orders: this.state.tableData
          .map((td) => {
            if (selectedRoreOrder.includes(td.id)) {
              return td.rore_order
            }
            return false
          })
          .filter(Boolean),
      }

      try {
        let userRes = await HTTPService({
          method: 'POST',
          url: `sampleorder-cancel/`,
          data: {
            ...dataObj,
          },
        })
        if (userRes.success) {
          message.success('Mass Cancelled Successfully.')
          this.setState({ loader: false })
          this.getOrderList('', this.state.currentPage)
        } else {
          message.error(userRes.message)
          this.setState({ loader: false })
          this.getOrderList('', this.state.currentPage)
        }
      } catch (error) {
        message.error(' Mass Cancel Failed')
        this.setState({ loader: false })
        this.getOrderList('', this.state.currentPage)
      }
    } else message.info('Please select a sample order')
  }

  exportData = () => {
    if (this.state.isFilter) {
      this.getExportDataFiltered()
    } else {
      this.getExportData()
    }
  }

  async getExportDataFiltered() {
    let urlLink = `export/sampleorder-xls/?${this.state.searchText}`
    let responseURL
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: urlLink,
      })
      if (userRes.success) {
        responseURL = userRes.data.url
        this.setState({ exportURL: responseURL })
        window.location.href = responseURL
      } else {
        this.setState({ loader: false })
        message.error(userRes.message)
      }
    } catch (error) {
      this.setState({ loader: false })
    }
  }

  async getExportData() {
    let urlLink = `export/sampleorder-xls/`
    let responseURL
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: urlLink,
      })
      if (userRes.success) {
        responseURL = userRes.data.url
        this.setState({ exportURL: responseURL })
        window.location.href = responseURL
      } else {
        this.setState({ loader: false })
        message.error(userRes.message)
      }
    } catch (error) {
      this.setState({ loader: false })
    }
  }

  //function to delete multiple orders
  handleDeleteOrder = async () => {
    if (this.state.selectedRoreOrder.length > 0) {
      let dataObj = {
        order_ids: this.state.selectedRoreOrder,
      }
      try {
        let userRes = await HTTPService({
          method: 'POST',
          url: `delete-sampleorder/`,
          data: {
            ...dataObj,
          },
        })
        if (userRes.success) {
          message.success('Delete Successfully.')
          this.setState({ loader: false })
          this.getOrderList('', this.state.currentPage)
        } else {
          message.error(userRes.message)
          this.setState({ loader: false })
          this.getOrderList('', this.state.currentPage)
        }
      } catch (error) {
        message.error('Delete Failed')
        this.setState({ loader: false })
        this.getOrderList('', this.state.currentPage)
      }
    } else message.info('Please select a sample order')
  }
  render() {
    this.columns = [
      /* {
        title: 'Order_ID',
        dataIndex: 'id',
        width: 130,
        ...this.getColumnSearchProps('id'),
        fixed: 'left',
        className: this.state.highlightFilterArray.includes('id')
          ? 'filtered_text'
          : '',
      }, */
      {
        title: 'Rore Order',
        dataIndex: 'rore_order',
        width: 130,
        ...this.getColumnSearchProps('rore_order'),
        fixed: 'left',
        className: this.state.highlightFilterArray.includes('rore_order')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Order Status',
        dataIndex: 'current_order_status',
        width: 170,
        ...this.getColumnSearchProps('current_order_status'),
        fixed: 'left',
        className: this.state.highlightFilterArray.includes(
          'current_order_status'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Web Order',
        dataIndex: 'web_order',
        width: 130,
        ...this.getColumnSearchProps('web_order'),
        fixed: 'left',
        className: this.state.highlightFilterArray.includes('web_order')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Web Order Item',
        dataIndex: 'web_order_itm',
        width: 130,
        ...this.getColumnSearchProps('web_order_itm'),
        fixed: 'left',
        className: this.state.highlightFilterArray.includes('web_order_itm')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'MDLN Order',
        dataIndex: 'mdln_order',
        width: 200,
        ...this.getColumnSearchProps('mdln_order'),
        className: this.state.highlightFilterArray.includes('mdln_order')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'ERP Order No.',
        dataIndex: 'erp_order_number',
        width: 200,
        ...this.getColumnSearchProps('erp_order_number'),
        className: this.state.highlightFilterArray.includes('erp_order_number')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'ERP Order Item',
        dataIndex: 'erp_order_item',
        width: 200,
        ...this.getColumnSearchProps('erp_order_item'),
        className: this.state.highlightFilterArray.includes('erp_order_item')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Channel',
        dataIndex: 'channel',
        width: 200,
        ...this.getColumnSearchProps('channel'),
        className: this.state.highlightFilterArray.includes('channel')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Part No.',
        dataIndex: 'part_number',
        width: 200,
        ...this.getColumnSearchProps('part_number'),
        className: this.state.highlightFilterArray.includes('part_number')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Sales Org Filter',
        dataIndex: 'sales_org_filter',
        width: 200,
        ...this.getColumnSearchProps('sales_org_filter'),
        className: this.state.highlightFilterArray.includes('sales_org_filter')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Order Qty',
        dataIndex: 'order_qty',
        width: 200,
        ...this.getColumnSearchProps('order_qty'),
        className: this.state.highlightFilterArray.includes('order_qty')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Approved Qty',
        dataIndex: 'approved_qty',
        width: 200,
        ...this.getColumnSearchProps('approved_qty'),
        className: this.state.highlightFilterArray.includes('approved_qty')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Order Fulfill Company',
        dataIndex: 'order_fulfill_company',
        width: 200,
        ...this.getColumnSearchProps('order_fulfill_company'),
        className: this.state.highlightFilterArray.includes(
          'order_fulfill_company'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Disti/ERP Ack Qty',
        dataIndex: 'disti_erp_ack_qty',
        width: 200,
        ...this.getColumnSearchProps('disti_erp_ack_qty'),
        className: this.state.highlightFilterArray.includes('disti_erp_ack_qty')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Disti/ERP Commit Date',
        dataIndex: 'disti_erp_commit_date',
        width: 200,
        ...this.getColumnSearchProps('disti_erp_commit_date'),
        className: this.state.highlightFilterArray.includes(
          'disti_erp_commit_date'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Shipped Qty',
        dataIndex: 'shipped_qty',
        width: 200,
        ...this.getColumnSearchProps('shipped_qty'),
      },
      {
        title: 'Disti/ERP Backorder Qty',
        dataIndex: 'disti_erp_backorder_qty',
        width: 200,
        ...this.getColumnSearchProps('disti_erp_backorder_qty'),
      },
      {
        title: 'Req Name',
        dataIndex: 'req_name',
        width: 200,
        ...this.getColumnSearchProps('req_name'),
        className: this.state.highlightFilterArray.includes('req_name')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Request email',
        dataIndex: 'req_email',
        width: 280,
        ...this.getColumnSearchProps('req_email'),
        className: this.state.highlightFilterArray.includes('req_email')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Req Tel.Number',
        dataIndex: 'req_tel_number',
        width: 280,
        ...this.getColumnSearchProps('req_tel_number'),
        className: this.state.highlightFilterArray.includes('req_tel_number')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Company Name',
        dataIndex: 'company_name',
        width: 280,
        ...this.getColumnSearchProps('company_name'),
        className: this.state.highlightFilterArray.includes('company_name')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Ship To Name',
        dataIndex: 'shipto_name',
        width: 300,
        ...this.getColumnSearchProps('shipto_name'),
        className: this.state.highlightFilterArray.includes('shipto_name')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Ship To Address1',
        dataIndex: 'shipto_addr1',
        width: 300,
        ...this.getColumnSearchProps('shipto_addr1'),
        className: this.state.highlightFilterArray.includes('shipto_addr1')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Ship To Address2',
        dataIndex: 'shipto_addr2',
        width: 300,
        ...this.getColumnSearchProps('shipto_addr2'),
        className: this.state.highlightFilterArray.includes('shipto_addr2')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Ship To City',
        dataIndex: 'shipto_city',
        width: 200,
        ...this.getColumnSearchProps('shipto_city'),
        className: this.state.highlightFilterArray.includes('shipto_city')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Ship To State',
        dataIndex: 'shipto_state',
        width: 200,
        ...this.getColumnSearchProps('shipto_state'),
        className: this.state.highlightFilterArray.includes('shipto_state')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Ship To State ISO',
        dataIndex: 'shipto_state_ISO',
        width: 200,
        ...this.getColumnSearchProps('shipto_state_ISO'),
        className: this.state.highlightFilterArray.includes('shipto_state_ISO')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Ship To ZIP',
        dataIndex: 'shipto_zip',
        width: 200,
        ...this.getColumnSearchProps('shipto_zip'),
        className: this.state.highlightFilterArray.includes('shipto_zip')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Ship To Country',
        dataIndex: 'shipto_country',
        width: 200,
        ...this.getColumnSearchProps('shipto_country'),
        className: this.state.highlightFilterArray.includes('shipto_country')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Ship To Country ISO',
        dataIndex: 'shipto_country_ISO',
        width: 200,
        ...this.getColumnSearchProps('shipto_country_ISO'),
        className: this.state.highlightFilterArray.includes(
          'shipto_country_ISO'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Contact First Name',
        dataIndex: 'contact_first_name',
        width: 200,
        ...this.getColumnSearchProps('contact_first_name'),
        className: this.state.highlightFilterArray.includes(
          'contact_first_name'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Contact Last Name',
        dataIndex: 'contact_last_name',
        width: 200,
        ...this.getColumnSearchProps('contact_last_name'),
        className: this.state.highlightFilterArray.includes('contact_last_name')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Contact Email',
        dataIndex: 'contact_email',
        width: 280,
        ...this.getColumnSearchProps('contact_email'),
        className: this.state.highlightFilterArray.includes('contact_email')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Contact Tel.Number',
        dataIndex: 'contact_tel_number',
        width: 280,
        ...this.getColumnSearchProps('contact_tel_number'),
        className: this.state.highlightFilterArray.includes(
          'contact_tel_number'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'USCI',
        dataIndex: 'USCI',
        width: 200,
        ...this.getColumnSearchProps('USCI'),
        className: this.state.highlightFilterArray.includes('USCI')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Company Location',
        dataIndex: 'company_location',
        width: 280,
        ...this.getColumnSearchProps('company_location'),
        className: this.state.highlightFilterArray.includes('company_location')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Company Address1',
        dataIndex: 'company_addr1',
        width: 280,
        ...this.getColumnSearchProps('company_addr1'),
        className: this.state.highlightFilterArray.includes('company_addr1')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Company Address2',
        dataIndex: 'company_addr2',
        width: 280,
        ...this.getColumnSearchProps('company_addr2'),
        className: this.state.highlightFilterArray.includes('company_addr2')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Company City',
        dataIndex: 'company_city',
        width: 200,
        ...this.getColumnSearchProps('company_city'),
        className: this.state.highlightFilterArray.includes('company_city')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Company State',
        dataIndex: 'company_state',
        width: 200,
        ...this.getColumnSearchProps('company_state'),
        className: this.state.highlightFilterArray.includes('company_state')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Company State ISO',
        dataIndex: 'company_state_ISO',
        width: 200,
        ...this.getColumnSearchProps('company_state_ISO'),
        className: this.state.highlightFilterArray.includes('company_state_ISO')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Company zip',
        dataIndex: 'company_zip',
        width: 200,
        ...this.getColumnSearchProps('company_zip'),
        className: this.state.highlightFilterArray.includes('company_zip')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Company Country',
        dataIndex: 'company_country',
        width: 200,
        ...this.getColumnSearchProps('company_country'),
        className: this.state.highlightFilterArray.includes('company_country')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Company Country ISO',
        dataIndex: 'company_country_ISO',
        width: 200,
        ...this.getColumnSearchProps('company_country_ISO'),
        className: this.state.highlightFilterArray.includes(
          'company_country_ISO'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Company Website',
        dataIndex: 'company_website',
        width: 280,
        ...this.getColumnSearchProps('company_website'),
        className: this.state.highlightFilterArray.includes('company_website')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Disti/ERP Error Reason',
        dataIndex: 'disti_erp_error_reason',
        width: 200,
        ...this.getColumnSearchProps('disti_erp_error_reason'),
        ellipsis: {
          showTitle: true,
        },
        render: (text) => (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),

        className: this.state.highlightFilterArray.includes(
          'disti_erp_error_reason'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'RORE Man Reject',
        dataIndex: 'rore_man_reject',
        width: 200,
        ...this.getColumnSearchProps('rore_man_reject'),
        render: (text) => (
          <span>{text == 'Y' ? 'Y' : text == 'N' ? 'N' : ''}</span>
        ),
        className: this.state.highlightFilterArray.includes('rore_man_reject')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'RORE Order Reject Username',
        dataIndex: 'rore_order_rej_username',
        width: 280,
        ...this.getColumnSearchProps('rore_order_rej_username'),
      },
      {
        title: 'Non Approve Reason',
        dataIndex: 'non_approv_reason',
        width: 200,
        ...this.getColumnSearchProps('non_approv_reason'),
        className: this.state.highlightFilterArray.includes('non_approv_reason')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Disti Ship Source',
        dataIndex: 'disti_ship_source',
        width: 200,
        ...this.getColumnSearchProps('disti_ship_source'),
      },
      {
        title: 'Ship Type',
        dataIndex: 'ship_type',
        width: 200,
        ...this.getColumnSearchProps('ship_type'),
      },
      {
        title: 'Carrier Name',
        dataIndex: 'carrier_name',
        width: 200,
        ...this.getColumnSearchProps('carrier_name'),
      },
      {
        title: 'Waybill Number',
        dataIndex: 'waybill',
        width: 200,
        ...this.getColumnSearchProps('waybill'),
      },
      {
        title: 'Packlist',
        dataIndex: 'packlist',
        width: 200,
        ...this.getColumnSearchProps('packlist'),
      },
      {
        title: 'Order Ship Company',
        dataIndex: 'order_ship_company',
        width: 200,
        ...this.getColumnSearchProps('order_ship_company'),
      },
      {
        title: 'Opp Number',
        dataIndex: 'Opp_number',
        width: 200,
        ...this.getColumnSearchProps('Opp_number'),
        className: this.state.highlightFilterArray.includes('Opp_number')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'EAU',
        dataIndex: 'eau',
        width: 200,
        ...this.getColumnSearchProps('eau'),
        className: this.state.highlightFilterArray.includes('eau')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Program',
        dataIndex: 'program',
        width: 200,
        ...this.getColumnSearchProps('program'),
        className: this.state.highlightFilterArray.includes('program')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Target App',
        dataIndex: 'target_app',
        width: 200,
        ...this.getColumnSearchProps('target_app'),
        ellipsis: {
          showTitle: true,
        },
        render: (text) => (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
        className: this.state.highlightFilterArray.includes('target_app')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'End Product',
        dataIndex: 'end_product',
        width: 200,
        ...this.getColumnSearchProps('end_product'),
        ellipsis: {
          showTitle: true,
        },
        render: (text) => (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
        className: this.state.highlightFilterArray.includes('end_product')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Itar',
        dataIndex: 'itar',
        width: 200,
        ...this.getColumnSearchProps('itar'),
        render: (text) => (
          <span>{text == 'Y' ? 'Y' : text == 'N' ? 'N' : ''}</span>
        ),
        className: this.state.highlightFilterArray.includes('itar')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Controlled Part',
        dataIndex: 'controlled_part',
        width: 200,
        ...this.getColumnSearchProps('controlled_part'),
        render: (text) => (
          <span>{text == 'Y' ? 'Y' : text == 'N' ? 'N' : ''}</span>
        ),
        className: this.state.highlightFilterArray.includes('controlled_part')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Bypass Disti',
        dataIndex: 'bypass_disti',
        width: 280,
        ...this.getColumnSearchProps('bypass_disti'),
        render: (text) => (
          <span>{text == 'Y' ? 'Y' : text == 'N' ? 'N' : ''}</span>
        ),
      },
      {
        title: 'Can disti Ship',
        dataIndex: 'can_disti_ship',
        width: 200,
        ...this.getColumnSearchProps('can_disti_ship'),
        render: (text) => (
          <span>{text == 'Y' ? 'Y' : text == 'N' ? 'N' : ''}</span>
        ),
        className: this.state.highlightFilterArray.includes('can_disti_ship')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'BR Name Check',
        dataIndex: 'br_name_check',
        width: 200,
        ...this.getColumnSearchProps('br_name_check'),
        render: (text) => (
          <span>{text == 'Pass' ? 'Pass' : text == 'Fail' ? 'Fail' : ''}</span>
        ),
        className: this.state.highlightFilterArray.includes('br_name_check')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'BR Part Check',
        dataIndex: 'br_part_check',
        width: 200,
        ...this.getColumnSearchProps('br_part_check'),
        render: (text) => (
          <span>{text == 'Pass' ? 'Pass' : text == 'Fail' ? 'Fail' : ''}</span>
        ),
        className: this.state.highlightFilterArray.includes('br_part_check')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'BR Itar Check',
        dataIndex: 'br_itar_check',
        width: 200,
        ...this.getColumnSearchProps('br_itar_check'),
        render: (text) => (
          <span>{text == 'Pass' ? 'Pass' : text == 'Fail' ? 'Fail' : ''}</span>
        ),
        className: this.state.highlightFilterArray.includes('br_itar_check')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'BR Export Countrol Check',
        dataIndex: 'br_export_cntrl_check',
        width: 200,
        ...this.getColumnSearchProps('br_export_cntrl_check'),
        render: (text) => (
          <span>{text == 'Pass' ? 'Pass' : text == 'Fail' ? 'Fail' : ''}</span>
        ),
        className: this.state.highlightFilterArray.includes(
          'br_export_cntrl_check'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'BR BU Check',
        dataIndex: 'br_bu_check',
        width: 200,
        ...this.getColumnSearchProps('br_bu_check'),
        render: (text) => (
          <span>{text == 'Pass' ? 'Pass' : text == 'Fail' ? 'Fail' : ''}</span>
        ),
        className: this.state.highlightFilterArray.includes('br_bu_check')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'BR Div Check',
        dataIndex: 'br_div_check',
        width: 200,
        ...this.getColumnSearchProps('br_div_check'),
        render: (text) => (
          <span>{text == 'Pass' ? 'Pass' : text == 'Fail' ? 'Fail' : ''}</span>
        ),
        className: this.state.highlightFilterArray.includes('br_div_check')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'BR Mkt Group Check',
        dataIndex: 'br_mkt_grp_check',
        width: 200,
        ...this.getColumnSearchProps('br_mkt_grp_check'),
        render: (text) => (
          <span>{text == 'Pass' ? 'Pass' : text == 'Fail' ? 'Fail' : ''}</span>
        ),
        className: this.state.highlightFilterArray.includes('br_mkt_grp_check')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'BR Coo Check',
        dataIndex: 'br_coo_check',
        width: 200,
        ...this.getColumnSearchProps('br_coo_check'),
        render: (text) => (
          <span>{text == 'Pass' ? 'Pass' : text == 'Fail' ? 'Fail' : ''}</span>
        ),
        className: this.state.highlightFilterArray.includes('br_coo_check')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'BR Channel Check',
        dataIndex: 'br_channel_check',
        width: 200,
        ...this.getColumnSearchProps('br_channel_check'),
        render: (text) => (
          <span>{text == 'Pass' ? 'Pass' : text == 'Fail' ? 'Fail' : ''}</span>
        ),
        className: this.state.highlightFilterArray.includes('br_channel_check')
          ? 'filtered_text'
          : '',
      },
      {
        title: ' BR Ship-to Name/Country Check',
        dataIndex: 'br_shipto_name_check',
        width: 280,
        ...this.getColumnSearchProps('br_shipto_name_check'),
      },
      {
        title: 'Core Inv Qty',
        dataIndex: 'core_inv_qty',
        width: 200,
        ...this.getColumnSearchProps('core_inv_qty'),
        className: this.state.highlightFilterArray.includes('core_inv_qty')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Consign Inv Qty',
        dataIndex: 'consign_inv_qty',
        width: 200,
        ...this.getColumnSearchProps('consign_inv_qty'),
        className: this.state.highlightFilterArray.includes('consign_inv_qty')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Disti Part Not Found',
        dataIndex: 'disti_part_not_found',
        width: 200,
        ...this.getColumnSearchProps('disti_part_not_found'),
        render: (text) => (
          <span>{text == 'Y' ? 'Y' : text == 'N' ? 'N' : ''}</span>
        ),
        className: this.state.highlightFilterArray.includes(
          'disti_part_not_found'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'International Shipment',
        dataIndex: 'intl_Shipment',
        width: 200,
        ...this.getColumnSearchProps('intl_Shipment'),
      },
      {
        title: 'Grouping ID',
        dataIndex: 'grouping_ID',
        width: 200,
        ...this.getColumnSearchProps('grouping_ID'),
        className: this.state.highlightFilterArray.includes('grouping_ID')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Order reproc Username',
        dataIndex: 'order_reproc_username',
        width: 200,
        ...this.getColumnSearchProps('order_reproc_username'),
        className: this.state.highlightFilterArray.includes(
          'order_reproc_username'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Manually Changed Username',
        dataIndex: 'manually_changed_username',
        width: 280,
        ...this.getColumnSearchProps('manually_changed_username'),
      },
      {
        title: 'Comments',
        dataIndex: 'comments',
        width: 200,
        ...this.getColumnSearchProps('comments'),
        ellipsis: {
          showTitle: true,
        },
        render: (text) => (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
        className: this.state.highlightFilterArray.includes('comments')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Consign Ship Conf Sent Date',
        dataIndex: 'consign_ship_conf_sent_date',
        width: 280,
        ...this.getColumnSearchProps('consign_ship_conf_sent_date'),
      },
      {
        title: 'Consign Ship Conf Sent Time',
        dataIndex: 'consign_ship_conf_sent_time',
        width: 280,
        ...this.getColumnSearchProps('consign_ship_conf_sent_time'),
      },
      {
        title: 'Delay Timer Expiration Date',
        dataIndex: 'delay_timer_expiration_date',
        width: 280,
        ...this.getColumnSearchProps('delay_timer_expiration_date'),
      },
      {
        title: 'Disti/ERP Ack Rec Date',
        dataIndex: 'disti_erp_ack_rec_date',
        width: 200,
        ...this.getColumnSearchProps('disti_erp_ack_rec_date'),
        className: this.state.highlightFilterArray.includes(
          'disti_erp_ack_rec_date'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Disti/ERP Ack Rec Time',
        dataIndex: 'disti_erp_ack_rec_time',
        width: 200,
        ...this.getColumnSearchProps('disti_erp_ack_rec_time'),
        className: this.state.highlightFilterArray.includes(
          'disti_erp_ack_rec_time'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Disti/ERP Backorder Rec Date',
        dataIndex: 'disti_erp_backorder_rec_date',
        width: 280,
        ...this.getColumnSearchProps('disti_erp_backorder_rec_date'),
      },
      {
        title: 'Disti/ERP Backorder Rec Time',
        dataIndex: 'disti_erp_backorder_rec_time',
        width: 280,
        ...this.getColumnSearchProps('disti_erp_backorder_rec_time'),
      },
      {
        title: 'Disti/ERP Error Rec Date',
        dataIndex: 'disti_erp_error_rec_date',
        width: 200,
        ...this.getColumnSearchProps('disti_erp_error_rec_date'),
      },
      {
        title: 'Disti/ERP Error Rec Time',
        dataIndex: 'disti_erp_error_rec_time',
        width: 200,
        ...this.getColumnSearchProps('disti_erp_error_rec_time'),
      },
      {
        title: 'Duplicate Date',
        dataIndex: 'dup_date',
        width: 200,
        ...this.getColumnSearchProps('dup_date'),
      },
      {
        title: 'Duplicate Time',
        dataIndex: 'dup_time',
        width: 200,
        ...this.getColumnSearchProps('dup_time'),
      },
      {
        title: 'International Shipment Start Date',
        dataIndex: 'intl_shipment_start_date',
        width: 280,
        ...this.getColumnSearchProps('intl_shipment_start_date'),
      },
      {
        title: 'International Shipment Start Time',
        dataIndex: 'intl_shipment_start_time',
        width: 280,
        ...this.getColumnSearchProps('intl_shipment_start_time'),
      },
      {
        title: 'Lead To Eloq Date',
        dataIndex: 'lead_to_eloq_date',
        width: 200,
        ...this.getColumnSearchProps('lead_to_eloq_date'),
        className: this.state.highlightFilterArray.includes('lead_to_eloq_date')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Lead To Eloq Time',
        dataIndex: 'lead_to_eloq_time',
        width: 200,
        ...this.getColumnSearchProps('lead_to_eloq_time'),
        className: this.state.highlightFilterArray.includes('lead_to_eloq_time')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Manually Changed Date',
        dataIndex: 'manually_changed_date',
        width: 280,
        ...this.getColumnSearchProps('manually_changed_date'),
      },
      {
        title: 'MDLN Approve Reject Date',
        dataIndex: 'mdln_approv_rej_date',
        width: 280,
        ...this.getColumnSearchProps('mdln_approv_rej_date'),
        className: this.state.highlightFilterArray.includes(
          'mdln_approv_rej_date'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'MDLN Approve Reject Time',
        dataIndex: 'mdln_approv_rej_time',
        width: 280,
        ...this.getColumnSearchProps('mdln_approv_rej_time'),
        className: this.state.highlightFilterArray.includes(
          'mdln_approv_rej_time'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Order Ack To Customer Date',
        dataIndex: 'ord_ack_to_cust_date',
        width: 280,
        ...this.getColumnSearchProps('ord_ack_to_cust_date'),
        className: this.state.highlightFilterArray.includes(
          'ord_ack_to_cust_date'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Order Ack To Customer Time',
        dataIndex: 'ord_ack_to_cust_time',
        width: 280,
        ...this.getColumnSearchProps('ord_ack_to_cust_time'),
        className: this.state.highlightFilterArray.includes(
          'ord_ack_to_cust_time'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Order Cancel To Customer Date',
        dataIndex: 'ord_cancel_to_cust_date',
        width: 280,
        ...this.getColumnSearchProps('ord_cancel_to_cust_date'),
        className: this.state.highlightFilterArray.includes(
          'ord_cancel_to_cust_date'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Order Cancel To Customer Time',
        dataIndex: 'ord_cancel_to_cust_time',
        width: 280,
        ...this.getColumnSearchProps('ord_cancel_to_cust_time'),
        className: this.state.highlightFilterArray.includes(
          'ord_cancel_to_cust_time'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Order Conf To Customer Date',
        dataIndex: 'ord_conf_to_cust_date',
        width: 280,
        ...this.getColumnSearchProps('ord_conf_to_cust_date'),
        className: this.state.highlightFilterArray.includes(
          'ord_conf_to_cust_date'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Order Conf To Customer Time',
        dataIndex: 'ord_conf_to_cust_time',
        width: 280,
        ...this.getColumnSearchProps('ord_conf_to_cust_time'),
        className: this.state.highlightFilterArray.includes(
          'ord_conf_to_cust_time'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Order Rej To Customer Date',
        dataIndex: 'ord_rej_to_cust_date',
        width: 280,
        ...this.getColumnSearchProps('ord_rej_to_cust_date'),
        className: this.state.highlightFilterArray.includes(
          'ord_rej_to_cust_date'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Order Rej To Customer Time',
        dataIndex: 'ord_rej_to_cust_time',
        width: 280,
        ...this.getColumnSearchProps('ord_rej_to_cust_time'),
        className: this.state.highlightFilterArray.includes(
          'ord_rej_to_cust_time'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Order Fulfill Date',
        dataIndex: 'order_fulfill_sent_date',
        width: 200,
        ...this.getColumnSearchProps('order_fulfill_sent_date'),
        className: this.state.highlightFilterArray.includes(
          'order_fulfill_sent_date'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Order Fulfill Time',
        dataIndex: 'order_fulfill_sent_time',
        width: 200,
        ...this.getColumnSearchProps('order_fulfill_sent_time'),
        className: this.state.highlightFilterArray.includes(
          'order_fulfill_sent_time'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Order reproc Date',
        dataIndex: 'order_reproc_date',
        width: 200,
        ...this.getColumnSearchProps('order_reproc_date'),
        className: this.state.highlightFilterArray.includes('order_reproc_date')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Order reproc Time',
        dataIndex: 'order_reproc_time',
        width: 200,
        ...this.getColumnSearchProps('order_reproc_time'),
        className: this.state.highlightFilterArray.includes('order_reproc_time')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'RORE Order Created Date',
        dataIndex: 'rore_order_creat_date',
        width: 280,
        ...this.getColumnSearchProps('rore_order_creat_date'),
        className: this.state.highlightFilterArray.includes(
          'rore_order_creat_date'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'RORE Order Created Time',
        dataIndex: 'rore_order_creat_time',
        width: 280,
        ...this.getColumnSearchProps('rore_order_creat_time'),
        className: this.state.highlightFilterArray.includes(
          'rore_order_creat_time'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'RORE Order Modified Date',
        dataIndex: 'rore_order_modified_date',
        width: 280,
        ...this.getColumnSearchProps('rore_order_modified_date'),
      },
      {
        title: 'RORE Order Modified Time',
        dataIndex: 'rore_order_modified_time',
        width: 280,
        ...this.getColumnSearchProps('rore_order_modified_time'),
      },
      {
        title: 'RORE Order Reject Date',
        dataIndex: 'rore_order_rej_date',
        width: 200,
        ...this.getColumnSearchProps('rore_order_rej_date'),
      },
      {
        title: 'RORE Order Reject Time',
        dataIndex: 'rore_order_rej_time',
        width: 280,
        ...this.getColumnSearchProps('rore_order_rej_time'),
      },
      {
        title: 'Sent To MDLN DATE',
        dataIndex: 'sent_to_mdln_date',
        width: 200,
        ...this.getColumnSearchProps('sent_to_mdln_date'),
        className: this.state.highlightFilterArray.includes('sent_to_mdln_date')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Sent To MDLN Time',
        dataIndex: 'sent_to_mdln_time',
        width: 200,
        ...this.getColumnSearchProps('sent_to_mdln_time'),
        className: this.state.highlightFilterArray.includes('sent_to_mdln_time')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Ship Ack To Customer Date',
        dataIndex: 'ship_ack_to_cust_date',
        width: 280,
        ...this.getColumnSearchProps('ship_ack_to_cust_date'),
        className: this.state.highlightFilterArray.includes(
          'ship_ack_to_cust_date'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Ship Ack To Customer Time',
        dataIndex: 'ship_ack_to_cust_time',
        width: 280,
        ...this.getColumnSearchProps('ship_ack_to_cust_time'),
        className: this.state.highlightFilterArray.includes(
          'ship_ack_to_cust_time'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Ship Conf Date',
        dataIndex: 'ship_conf_date',
        width: 200,
        ...this.getColumnSearchProps('ship_conf_date'),
      },
      {
        title: 'Ship Conf Time',
        dataIndex: 'ship_conf_time',
        width: 200,
        ...this.getColumnSearchProps('ship_conf_time'),
      },
      {
        title: 'Ship Conf MDLN Sent Date',
        dataIndex: 'ship_conf_mdln_sent_date',
        width: 280,
        ...this.getColumnSearchProps('ship_conf_mdln_sent_date'),
      },
      {
        title: 'Ship Conf MDLN Sent Time',
        dataIndex: 'ship_conf_mdln_sent_time',
        width: 280,
        ...this.getColumnSearchProps('ship_conf_mdln_sent_time'),
      },
      {
        title: 'Web Eoh Call Date',
        dataIndex: 'web_eoh_call_date',
        width: 200,
        ...this.getColumnSearchProps('web_eoh_call_date'),
        className: this.state.highlightFilterArray.includes('web_eoh_call_date')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Web Eoh Cal Time',
        dataIndex: 'web_eoh_cal_time',
        width: 200,
        ...this.getColumnSearchProps('web_eoh_cal_time'),
        className: this.state.highlightFilterArray.includes('web_eoh_cal_time')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Manually Changed Time',
        dataIndex: 'manually_changed_time',
        width: 280,
        ...this.getColumnSearchProps('manually_changed_time'),
      },
      {
        title: 'Parent company website',
        dataIndex: 'parent_company_website',
        width: 280,
        ...this.getColumnSearchProps('parent_company_website'),
      },
      {
        title: 'Manual Cancellation Reason',
        dataIndex: 'manually_cancellation_reason',
        width: 280,
        ...this.getColumnSearchProps('manually_cancellation_reason'),
        ellipsis: {
          showTitle: true,
        },
        render: (text) => (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
      },
      {
        title: 'Parent company',
        dataIndex: 'parent_company',
        width: 280,
        ...this.getColumnSearchProps('parent_company'),
        className: this.state.highlightFilterArray.includes('parent_company')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Military use',
        dataIndex: 'military_use',
        width: 280,
        ...this.getColumnSearchProps('military_use'),
        className: this.state.highlightFilterArray.includes('military_use')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Business purpose',
        dataIndex: 'business_purpose',
        width: 280,
        ...this.getColumnSearchProps('business_purpose'),
        className: this.state.highlightFilterArray.includes('business_purpose')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Requestor company',
        dataIndex: 'requestor_company',
        width: 280,
        ...this.getColumnSearchProps('requestor_company'),
        className: this.state.highlightFilterArray.includes('requestor_company')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'DPL Released By',
        dataIndex: 'dpl_released_by',
        width: 280,
        ...this.getColumnSearchProps('dpl_released_by'),
        className: this.state.highlightFilterArray.includes('dpl_released_by')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'DPL Released On',
        dataIndex: 'dpl_released_on',
        width: 280,
        ...this.getColumnSearchProps('dpl_released_on'),
        className: this.state.highlightFilterArray.includes('dpl_released_on')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'DPL Released At',
        dataIndex: 'dpl_released_at',
        width: 280,
        ...this.getColumnSearchProps('dpl_released_at'),
        className: this.state.highlightFilterArray.includes('dpl_released_at')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Planner',
        dataIndex: 'planner',
        width: 200,
        ...this.getColumnSearchProps('planner'),
        className: this.state.highlightFilterArray.includes('planner')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Plant',
        dataIndex: 'plant',
        width: 200,
        ...this.getColumnSearchProps('plant'),
        className: this.state.highlightFilterArray.includes('plant')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Cancellation Flag',
        dataIndex: 'cancellation_flag',
        width: 200,
        ...this.getColumnSearchProps('cancellation_flag'),
        className: this.state.highlightFilterArray.includes('cancellation_flag')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'End customer Parent ID',
        dataIndex: 'end_customer_parent_ID',
        width: 200,
        ...this.getColumnSearchProps('end_customer_parent_ID'),
        className: this.state.highlightFilterArray.includes(
          'end_customer_parent_ID'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'End customer Location',
        dataIndex: 'end_customer_location',
        width: 200,
        ...this.getColumnSearchProps('end_customer_location'),
        className: this.state.highlightFilterArray.includes(
          'end_customer_location'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'End customer Name',
        dataIndex: 'end_customer',
        width: 200,
        ...this.getColumnSearchProps('end_customer'),
        className: this.state.highlightFilterArray.includes('end_customer')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'ModeIN Approver',
        dataIndex: 'modeln_approver',
        width: 200,
        ...this.getColumnSearchProps('modeln_approver'),
        className: this.state.highlightFilterArray.includes('modeln_approver')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Reason for cancellation',
        dataIndex: 'reason_for_cancellation',
        width: 280,
        ...this.getColumnSearchProps('reason_for_cancellation'),
        ellipsis: {
          showTitle: true,
        },
        render: (text) => (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
      },
      {
        title: 'EUS Hold',
        dataIndex: 'eus_hold',
        width: 200,
        ...this.getColumnSearchProps('eus_hold'),
        render: (text) => (
          <span>{text == 'Y' ? 'Y' : text == 'N' ? 'N' : ''}</span>
        ),
        className: this.state.highlightFilterArray.includes('eus_hold')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Auto Approve Disable',
        dataIndex: 'auto_approve_disable',
        width: 200,
        ...this.getColumnSearchProps('auto_approve_disable'),
        render: (text) => (
          <span>{text == 'Y' ? 'Y' : text == 'N' ? 'N' : ''}</span>
        ),
        className: this.state.highlightFilterArray.includes(
          'auto_approve_disable'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'EUS Hold Released By',
        dataIndex: 'eus_hold_released_by',
        width: 280,
        ...this.getColumnSearchProps('eus_hold_released_by'),
        className: this.state.highlightFilterArray.includes(
          'eus_hold_released_by'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'EUS Hold Released On',
        dataIndex: 'eus_hold_released_on',
        width: 280,
        ...this.getColumnSearchProps('eus_hold_released_on'),
        className: this.state.highlightFilterArray.includes(
          'eus_hold_released_on'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'EUS Hold Released At',
        dataIndex: 'eus_hold_released_at',
        width: 280,
        ...this.getColumnSearchProps('eus_hold_released_at'),
        className: this.state.highlightFilterArray.includes(
          'eus_hold_released_at'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'S4 Order Ack Mdln Receive Date',
        dataIndex: 's4_order_ack_mdln_receive_date',
        width: 280,
        ...this.getColumnSearchProps('s4_order_ack_mdln_receive_date'),
        className: this.state.highlightFilterArray.includes(
          's4_order_ack_mdln_receive_date'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'S4 Order Ack Mdln Receive Time',
        dataIndex: 's4_order_ack_mdln_receive_time',
        width: 280,
        ...this.getColumnSearchProps('s4_order_ack_mdln_receive_time'),
        className: this.state.highlightFilterArray.includes(
          's4_order_ack_mdln_receive_time'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'S4 Ship Conf Mdln Receive Date',
        dataIndex: 's4_ship_conf_mdln_receive_date',
        width: 280,
        ...this.getColumnSearchProps('s4_ship_conf_mdln_receive_date'),
        className: this.state.highlightFilterArray.includes(
          's4_ship_conf_mdln_receive_date'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'S4 Ship Conf Mdln Receive Time',
        dataIndex: 's4_ship_conf_mdln_receive_time',
        width: 280,
        ...this.getColumnSearchProps('s4_ship_conf_mdln_receive_time'),
        className: this.state.highlightFilterArray.includes(
          's4_ship_conf_mdln_receive_time'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'S4 Ship Conf Mdln Receive Time',
        dataIndex: 's4_ship_conf_mdln_receive_time',
        width: 280,
        ...this.getColumnSearchProps('s4_ship_conf_mdln_receive_time'),
        className: this.state.highlightFilterArray.includes(
          's4_ship_conf_mdln_receive_time'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Pulse Order',
        dataIndex: 'pulse_order',
        width: 200,
        ...this.getColumnSearchProps('pulse_order'),
        className: this.state.highlightFilterArray.includes(
          'pulse_order'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Pulse Order itm',
        dataIndex: 'pulse_order_itm',
        width: 200,
        ...this.getColumnSearchProps('pulse_order_itm'),
        className: this.state.highlightFilterArray.includes(
          'pulse_order_itm'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Gpak Sample',
        dataIndex: 'gpak_sample',
        width: 200,
        ...this.getColumnSearchProps('gpak_sample'),
        className: this.state.highlightFilterArray.includes(
          'gpak_sample'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Nvm Locked',
        dataIndex: 'nvm_locked',
        width: 200,
        ...this.getColumnSearchProps('nvm_locked'),
        className: this.state.highlightFilterArray.includes(
          'nvm_locked'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Specific Test Required',
        dataIndex: 'specific_test_required',
        width: 200,
        ...this.getColumnSearchProps('specific_test_required'),
        className: this.state.highlightFilterArray.includes(
          'specific_test_required'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Datasheet Rev No',
        dataIndex: 'datasheet_rev_no',
        width: 200,
        ...this.getColumnSearchProps('datasheet_rev_no'),
        className: this.state.highlightFilterArray.includes(
          'datasheet_rev_no'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Program Code Rev No',
        dataIndex: 'program_code_rev_no',
        width: 200,
        ...this.getColumnSearchProps('program_code_rev_no'),
        className: this.state.highlightFilterArray.includes(
          'program_code_rev_no'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Final Version',
        dataIndex: 'final_version',
        width: 200,
        ...this.getColumnSearchProps('final_version'),
        className: this.state.highlightFilterArray.includes(
          'final_version'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Silicon Revision',
        dataIndex: 'silicon_revision',
        width: 200,
        ...this.getColumnSearchProps('silicon_revision'),
        className: this.state.highlightFilterArray.includes(
          'silicon_revision'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Gpak Email Sent On',
        dataIndex: 'gpak_email_sent_on',
        width: 200,
        ...this.getColumnSearchProps('gpak_email_sent_on'),
        className: this.state.highlightFilterArray.includes(
          'gpak_email_sent_on'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Gpak Email Sent At',
        dataIndex: 'gpak_email_sent_at',
        width: 200,
        ...this.getColumnSearchProps('gpak_email_sent_at'),
        className: this.state.highlightFilterArray.includes(
          'gpak_email_sent_at'
        )
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Originating System',
        dataIndex: 'originating_system',
        width: 200,
        ...this.getColumnSearchProps('originating_system'),
        className: this.state.highlightFilterArray.includes(
          'originating_system'
        )
          ? 'filtered_text'
          : '',
      },


    ]
    //Action button column
    if (isPermission(PERMISSIONS_BY_NAME['Manage Sample Order'])) {
      this.columns.push({
        title: 'Action',
        dataIndex: 'action',
        width: 80,
        fixed: 'right',
        align: 'center',
        render: (text, record) =>
          this.state.tableData.length >= 1 ? (
            <Fragment>
              <span className="ico_edit">
                <EditOutlined
                  onClick={() => this.setFormStatus(record, 'edit')}
                />
              </span>
              {isPermission(PERMISSIONS_BY_NAME['Delete sample order']) && (
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={() => this.handleDelete(record.id)}
                >
                  <Link to="" className="ico_delete">
                    <DeleteOutlined />
                  </Link>
                </Popconfirm>
              )}
            </Fragment>
          ) : null,
      })
    } else if (isPermission(PERMISSIONS_BY_NAME['View Sample Order'])) {
      this.columns.push({
        title: 'Action',
        dataIndex: 'action',
        width: 80,
        fixed: 'right',
        align: 'center',
        render: (text, record) =>
          this.state.tableData.length >= 1 ? (
            <Fragment>
              <span className="ico_edit">
                <EyeOutlined onClick={() => this.setFormStatus(record)} />
              </span>
            </Fragment>
          ) : null,
      })
    } else if (isPermission(PERMISSIONS_BY_NAME['Edit sample order list'])) {
      this.columns.push({
        title: 'Action',
        dataIndex: 'action',
        width: '10%',
        render: (text, record) =>
          this.state.tableData.length >= 1 ? (
            <Fragment>
              <span className="ico_edit">
                <EditOutlined onClick={() => this.setFormStatus(record)} />
              </span>
            </Fragment>
          ) : null,
      })
    }
    const { isUser, visible, loading, tableData, currentPage, count } =
      this.state
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      }
    })

    //function to select records/rows of table
    const rowSelection = {
      selectedRowKeys: this.state.selectedRoreOrder,
      onChange: (selectedRoreOrders, selectedRows) => {
        const queryParams = new URLSearchParams()
        const extractedData = selectedRows.map((row) => ({
          rore_order: row.rore_order,
        }))
        extractedData.forEach((data, index) => {
          Object.entries(data).forEach(([key, value]) => {
            queryParams.append(key, value)
          })
        })
        this.setState({
          selectedRoreOrderID: queryParams.toString(),
          selectedRoreOrder: selectedRoreOrders,
        })
      },
    }

    const renderTable = () => {
      return (
        <div className="OrderTableEditStyles">
          <div className="OrderTableEdit_container">
            <div className="Table_top_row">
              <div className="Table_heading">Sample Order List</div>
              <div className="Table_action_btns">
                <div className="table_search_block">
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => this.getOrderList('')}
                  >
                    Reset
                  </Button>
                </div>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => this.setAuditLogStatus(true, true)}
                >
                  Audit Logs
                </Button>

                {isPermission(PERMISSIONS_BY_NAME['Mass DPL Release']) && (
                  <Button size="large" onClick={() => this.handleMassRelease()}>
                    Mass DPL Release
                  </Button>
                )}
                {isPermission(PERMISSIONS_BY_NAME['EUS Hold Release']) && (
                  <Button
                    size="large"
                    onClick={() => this.handleEusholdRelease()}
                  >
                    EUS Hold Release
                  </Button>
                )}
                {isPermission(PERMISSIONS_BY_NAME['Mass Cancel Orders']) && (
                  <Button size="large" onClick={() => this.handleMassCancel()}>
                    Mass Cancel
                  </Button>
                )}

                {isPermission(PERMISSIONS_BY_NAME['Manage Sample Order']) && (
                  <Button
                    size="large"
                    onClick={() => {
                      let selectedRoreOrder = this.state.selectedRoreOrder
                      let data = []
                      this.state.tableData.filter((i, key) => {
                        let mpnData = i.id
                        let index = selectedRoreOrder.indexOf(mpnData)
                        if (index != -1) {
                          data.push(this.state.tableData[key])
                        }
                      })
                      if (this.state.selectedRoreOrder.length > 0) {
                        let fileContent = []
                        for (let order of data) {
                          let csvObj = {}
                          for (let column of this.columns) {
                            if (column.dataIndex !== 'action') {
                              csvObj[column.dataIndex] = order[column.dataIndex]
                            }
                          }
                          fileContent.push(csvObj)
                        }
                        let headersToShow = this.columns
                          .map(
                            (column) =>
                              column.dataIndex !== 'action' && column.title
                          )
                          .filter((value) => value && value)
                        downloadExcel(
                          'RoreOrder_import',
                          fileContent,
                          headersToShow
                        )
                      } else message.info('Select orders to export')
                    }}
                  >
                    Export
                  </Button>
                )}
                <a
                  onClick={() => this.exportData()}
                  className="export_link"
                  download
                >
                  Export All
                </a>
                {isPermission(
                  PERMISSIONS_BY_NAME['Reprocess bypass disti']
                ) && (
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => {
                      this.handleReprocessBypass()
                    }}
                  >
                    Reprocess - Bypass disti
                  </Button>
                )}
                {isPermission(PERMISSIONS_BY_NAME['Reprocess']) && (
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => this.handleReprocess()}
                  >
                    Reprocess
                  </Button>
                )}
                {isPermission(PERMISSIONS_BY_NAME['Delete sample Orders']) && (
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => this.handleDeleteOrder()}
                  >
                    Delete order
                  </Button>
                )}
                {isPermission(PERMISSIONS_BY_NAME['Add sample order']) && (
                  <Button
                    onClick={() => this.setFormStatus('', 'add')}
                    size="large"
                    type="primary"
                  >
                    Add Order
                  </Button>
                )}
              </div>
            </div>
            <div className="layout_content_box OrderTableEdit_whitebox">
              <Table //sample order table
                className="Table_block_main"
                components={components}
                rowClassName={() => 'editable-row'}
                rowSelection={{
                  ...rowSelection,
                }}
                bordered
                scroll={{ x: 1500, y: 500 }}
                dataSource={tableData || null}
                columns={columns}
                pagination={{
                  onChange: this.onChangePageNumber,
                  current: currentPage,
                  total: count,
                  defaultPageSize: this.state.pageLimit,
                  showSizeChanger: true,
                  onShowSizeChange: this.onChangePageNumber,
                  showTotal: this.showTotal,
                }}
                loading={this.state.loader}
                rowKey="id"
              />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        {this.state.isFormOpen ? (
          this.state.loader ? (
            <div className="loader">
              <Spin size="large" />{' '}
            </div>
          ) : this.state.isAuditLog ? (
            <SampleRoreOrderAudit
              setFormStatus={this.setFormStatus}
              selectedRoreOrderID={this.state.selectedRoreOrderID}
            />
          ) : (
            <OrderForm // Add/Edit form
              setFormStatus={this.setFormStatus}
              currentOrder={this.state.currentOrder}
              isUser={isUser}
              currentPage={this.state.currentPage}
              onChangePageNumber={this.onChangePageNumber}
              pageLimit={this.state.pageLimit}
              isAddPart={this.state.isAddPart}
              history={this.props.history}
            />
          )
        ) : (
          renderTable()
        )}
      </div>
    )
  }
}

export default OrderTableEdit
